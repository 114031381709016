import React, { useState, useEffect } from 'react';
import { Tabs, Form, Input, Button, message, Row, Col, Select } from 'antd';
import { UserOutlined, CheckCircleOutlined, LeftOutlined, RightOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import GeneralService from "../../../../services/Producto_3/Fort_Capacidades/DHAA/cursoFortalecimientoDHAA";
import EscuestaAsistencia from "./EscuelaDHHAAsistencia"


const { Option } = Select;

const EscuelaDHAAForm = ({ generalFormRef, preguntasFormRef, incidenciasFormRef, onFinish, user }) => {


  const [currentTab, setCurrentTab] = useState(1);
  const [grupoEtnico, setGrupoEtnico] = useState([]);
  const [organizacion, setOrganizacion] = useState([]);
  const [grupoEtareo, setGrupoEtareo] = useState([]);
  const [vereda, setVereda] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [filterDepartamento, setFilterDepartamento] = useState(null);
  const [filteredMunicipios, setFilteredMunicipios] = useState([]);
  const [filterMunicipio, setFilterMunicipio] = useState(null);
  const [filterVereda, setFilterVereda] = useState(null);
  const [filteredVeredas, setFilteredVeredas] = useState([]);
  const [oidData, setOidData] = useState(null);
  const [incidencias, setIncidencias] = useState([]);
  const [accionesSociales, setAccionesSociales] = useState([]);
  const [problematicas, setProblematicas] = useState([]);
  const [selectedIncidencia, setSelectedIncidencia] = useState(null);
  const [selectedSocial, setSelectedSocial] = useState(null);
  const [selectedProblematica, setSelectedProblematica] = useState(null);
  const [dataUser, setDataUser] = useState(null);
  const [dataPreguntas, setDataPreguntas] = useState(null);
  const [dataIncidencias, setDataIncidencias] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          grupoEtareoData,
          departamentosData,
          municipiosData,
          veredaData,
          grupoEtnicoData,
          organizacionData,
          incidenciaData,
          socialData,
          problematicaData,
        ] = await Promise.all([
          GeneralService.getAllGrupoEtareo(),
          GeneralService.getDepartamentos(),
          GeneralService.getMunicipios(),
          GeneralService.getAllVereda(),
          GeneralService.getAllGrupoEtnico(),
          GeneralService.getOrganizacion(),
          GeneralService.getAllIncidencia(),
          GeneralService.getAllSocial(),
          GeneralService.getAllProblematica(),
        ]);

        setGrupoEtareo(grupoEtareoData);
        setDepartamentos(departamentosData);
        setMunicipios(municipiosData);
        setVereda(veredaData);
        setGrupoEtnico(grupoEtnicoData);
        setOrganizacion(organizacionData);
        setIncidencias(incidenciaData);
        setAccionesSociales(socialData);
        setProblematicas(problematicaData);

        // Indicar que los datos ya están cargados
        setDataLoaded(true);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user && dataLoaded) {
      setOidData(user);
      const fetchData = async () => {
        try {
          const usuarioData = await GeneralService.getGeneralById(user);
          setDataUser(usuarioData);
          const preguntaData = await GeneralService.getAllPreguntas(user);
          setDataPreguntas(preguntaData);
          const incidenciaData = await GeneralService.getAllIncidenciaGeneral(user);
          setDataIncidencias(incidenciaData);
          // Asegúrate de que el ref esté disponible antes de asignar valores
          if (generalFormRef.current) {
            generalFormRef.current.setFieldsValue({
              oid: usuarioData.oid,
              nombre_apellido: usuarioData.nombre_apellido,
              tipo_documento: String(usuarioData.tipo_documento),
              numero_documento: usuarioData.numero_documento,
              correo: usuarioData.correo,
              telefono: usuarioData.telefono,
              sexo: String(usuarioData.sexo),
              edad: usuarioData.edad,
              grupo_etareo: usuarioData.grupo_etareo,
              municipio: String(municipios.find(m => m.oid === usuarioData.municipio)?.municipio),
              departamento: String(departamentos.find(d => d.oid === municipios.find(m => m.oid === usuarioData.municipio)?.oid_depto)?.departamento),
              vereda: String(vereda.find(v => v.oid === usuarioData.vereda)?.vereda),
              pertenencia_etnica: usuarioData.pertenencia_etnica,
              organizacion: usuarioData.organizacion,
            });
          } else {
            console.error('generalFormRef.current no está definido.');
          }

          // Verificar que preguntasFormRef.current no sea undefined
          if (preguntasFormRef.current) {
            preguntasFormRef.current.setFieldsValue({
              oid: preguntaData.oid,
              pregunta_1: String(preguntaData.pregunta_1),
              pregunta_2: preguntaData.pregunta_2,
              pregunta_3: preguntaData.pregunta_3,
              pregunta_4: preguntaData.pregunta_4,
              pregunta_5: preguntaData.pregunta_5,
              pregunta_6: preguntaData.pregunta_6,
              pregunta_7: preguntaData.pregunta_7,
            });
          } else {
            console.error('preguntasFormRef.current no está definido.');
          }

          // Verificar que incidenciasFormRef.current no sea undefined
          if (incidenciasFormRef.current) {
            incidenciasFormRef.current.setFieldsValue({
              oid: incidenciaData.oid,
              accion_incidencia: incidenciaData.accion_incidencia,
              accion_social: incidenciaData.accion_social,
              accion_problematica: incidenciaData.accion_problematica,
            });
          } else {
            console.error('incidenciasFormRef.current no está definido.');
          }

        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };

      fetchData();
    }
  }, [user, oidData, dataLoaded]); // Recalcular solo cuando 'user' cambia


  const handleFinish = () => {
    onFinish();
    setCurrentTab(1);
  };

 

  const handleSubmit = async (section, values) => {
    if (section === 'general') {
      if (dataUser.oid) {
        try {
          const municipioID = typeof values.municipio === 'number'
            ? values.municipio
            : municipios.find(m => m.municipio === values.municipio)?.oid;

          const veredaID = typeof values.vereda === 'number'
            ? values.vereda
            : vereda.find(v => v.vereda === values.vereda)?.oid;

          // Actualiza los valores solo si es necesario
          const updatedValues = {
            ...values,
            municipio: municipioID,
            vereda: veredaID
          };

          const response = await GeneralService.updateGeneral(dataUser.oid, updatedValues); // Llama al servicio de actualización
          if (response.oid) {
            message.success('Datos actualizados exitosamente');
            handleNext();
          } else {
            message.error('Hubo un problema al actualizar los datos');
          }
        } catch (error) {
          message.error('Error al actualizar los datos: ' + error.message);
        }
      } else {
        try {
          const response = await GeneralService.createGeneral(values);
          setOidData(response.oid);
          if (response.oid) {
            message.success('Datos guardados exitosamente');
            handleNext();
          } else {
            message.error('Hubo un problema al guardar los datos');
          }
        } catch (error) {
          message.error('Error al guardar los datos: ' + error.message);
        }
      }
    }
    else if (section === 'preguntas') {
      if (dataPreguntas.oid) {
        try {
          const response = await GeneralService.updatePregunta(dataPreguntas.oid, values);  // Aquí llamas al método para actualizar
          if (response.oid) {
            message.success('Datos actualizados exitosamente');
            handleNext();  // Si todo va bien, procedes al siguiente paso
          } else {
            message.error('Hubo un problema al actualizar los datos');
          }
        } catch (error) {
          message.error('Error al actualizar los datos: ' + error.message);
        }
      } else {
        const updatedValues = { ...values, general: oidData };
        try {
          const response = await GeneralService.crearPregunta(updatedValues);
          if (response.oid) {
            message.success('Datos guardados exitosamente');
            handleNext();
          } else {
            message.error('Hubo un problema al guardar los datos');
          }
        } catch (error) {
          message.error('Error al guardar los datos: ' + error.message);
        }
      }
    }
    else if (section === 'incidencias') {
      if (dataIncidencias.oid) {
        try {
          const response = await GeneralService.updateIncidencia(dataIncidencias.oid, values);
          if (response.oid) {
            message.success('Datos actualizados exitosamente');
            handleNext();
          } else {
            message.error('Hubo un problema al actualizar los datos');
          }
        } catch (error) {
          message.error('Error al actualizar los datos: ' + error.message);
        }
      } else {
        const updatedValues = { ...values, general: oidData };
        try {
          const response = await GeneralService.crearIncidencia(updatedValues);
          if (response.oid) {
            message.success('Datos guardados exitosamente');
            handleNext();
          } else {
            message.error('Hubo un problema al guardar los datos');
          }
        } catch (error) {
          message.error('Error al guardar los datos: ' + error.message);
        }
      }
    }
  };

  const handleNext = async () => {
    try {
      await generalFormRef.current.validateFields();
      setCurrentTab((prevTab) => prevTab + 1);
    } catch (error) {
      console.log('Errores en la validación:', error);
    }
  };


  const handleBack = () => {
    setCurrentTab((prevTab) => (prevTab > 1 ? prevTab - 1 : prevTab));
  };

  const handleTabChange = async (key) => {
    if (Number(key) > currentTab) {
      try {
        await generalFormRef.current.validateFields();
        setCurrentTab(Number(key));
      } catch (error) {
        console.log('Errores en la validación:', error);
      }
    } else {
      setCurrentTab(Number(key));
    }
  };

  const handleDepartamentoChange = (value) => {
    setFilterDepartamento(value);
    const filtered = municipios.filter(municipio => municipio.oid_depto === value);
    setFilteredMunicipios(filtered);
    setFilterMunicipio(null);
  };

  const handleMunicipioChange = (value) => {
    setFilterMunicipio(value);
    const filtered = vereda.filter(v => v.oid_municipio === value);
    setFilteredVeredas(filtered);
    setFilterVereda(null);
  };

  const tabsItems = [
    {
      key: '1',
      label: (
        <span>
          <UserOutlined /> Datos Generales
        </span>
      ),
      children: (
        <Form ref={generalFormRef} onFinish={(values) => handleSubmit('general', values)} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="nombre_apellido" label="Nombre y Apellido" rules={[{ required: true }]}>
                <Input placeholder="Diligenciar nombre y apellido" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tipo_documento" label="Tipo de Documento" rules={[{ required: true }]}>
                <Select placeholder="Selecciona el tipo de documento">
                  <Select.Option value="1">Cédula de Ciudadanía</Select.Option>
                  <Select.Option value="2">Tarjeta de Identidad</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="numero_documento" label="Número de Documento" rules={[{ required: true }]}>
                <Input placeholder="Diligenciar número de documento" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="correo"
                label="Correo"
                rules={[
                  { required: true, message: 'Por favor ingrese el correo' },
                  { type: 'email', message: 'El correo ingresado no es válido' }
                ]}
              >
                <Input placeholder="Diligenciar el correo" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="telefono" label="Teléfono" rules={[{ required: true }]}>
                <Input placeholder="Diligenciar el teléfono " />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="sexo" label="Sexo" rules={[{ required: true }]}>
                <Select placeholder="Selecciona el genero">
                  <Select.Option value="1">Mujer</Select.Option>
                  <Select.Option value="2">Hombre</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="edad" label="Edad" rules={[{ required: true }]}>
                <Input placeholder="Diligenciar la edad" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="grupo_etareo"
                label="Grupo Etáreo"
                rules={[{ required: true, message: "Por favor selecciona un grupo etáreo" }]}
              >
                <Select placeholder="Selecciona un grupo etáreo">
                  {grupoEtareo.map((grupo) => (
                    <Option key={grupo.oid} value={grupo.oid}>
                      {grupo.etareo}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="departamento"
                label="Departamento"
                rules={[{ required: true, message: "Por favor selecciona un departamento" }]}
              >
                <Select
                  showSearch
                  placeholder="Filtrar por Departamento"
                  value={filterDepartamento || undefined}
                  onChange={handleDepartamentoChange}
                  onSearch={(value) => setFilterDepartamento(value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {departamentos.map(depto => (
                    <Option key={depto.oid} value={depto.oid}>{depto.departamento}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="municipio"
                label="Municipio"
                rules={[{ required: true, message: "Por favor selecciona un municipio" }]}
              >
                <Select
                  showSearch
                  placeholder="Filtrar por Municipio"
                  value={filterMunicipio || undefined}
                  onChange={handleMunicipioChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {filteredMunicipios.map(mun => (
                    <Option key={mun.oid} value={mun.oid}>{mun.municipio}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="vereda"
                label="Vereda"
                rules={[{ required: true, message: "Por favor selecciona una vereda" }]}
              >
                <Select
                  showSearch
                  placeholder="Filtrar por Vereda"
                  value={filterVereda || undefined}
                  onChange={(value) => setFilterVereda(value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {filteredVeredas.map(ver => (
                    <Option key={ver.oid} value={ver.oid}>{ver.vereda}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="pertenencia_etnica"
                label="Pertenencia Étnica"
                rules={[{ required: true, message: "Por favor selecciona una pertenencia étnica" }]}
              >
                <Select
                  showSearch
                  placeholder="Selecciona una Pertenencia Étnica"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {grupoEtnico.map(etnia => (
                    <Option key={etnia.oid} value={etnia.oid}>{etnia.etnico}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="organizacion"
                label="Organización"
                rules={[{ required: true, message: "Por favor selecciona una organización" }]}
              >
                <Select
                  showSearch
                  placeholder="Selecciona una Organización"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {organizacion.map(org => (
                    <Option key={org.oid} value={org.oid}>{org.organizacion}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            {currentTab > 1 && (
              <Button onClick={handleBack} type="default" icon={<LeftOutlined />}>
                Atrás
              </Button>
            )}
            {currentTab < 4 && (
              <Button onClick={() => generalFormRef.current.submit()} type="default" icon={<RightOutlined />}>
                Siguiente
              </Button>
            )}
          </div>
        </Form>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <QuestionCircleOutlined /> Preguntas
        </span>
      ),
      children: (
        <Form ref={preguntasFormRef} onFinish={(values) => handleSubmit('preguntas', values)} layout="vertical">
          <Form.Item
            name="pregunta_1"
            label="¿Se considera campesino o campesina?"
            rules={[{ required: true, message: 'Por favor, indique si se considera campesino o campesina.' }]}
          >
            <Select placeholder="Seleccione">
              <Option value="1">Sí</Option>
              <Option value="0">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="pregunta_2"
            label="¿Se encuentra en condición de discapacidad?"
            rules={[{ required: true, message: 'Por favor, indique si se encuentra en condición de discapacidad.' }]}
          >
            <Select placeholder="Seleccione">
              <Option value={1}>Sí</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="pregunta_3"
            label="¿Está reconocido como víctima del conflicto armado?"
            rules={[{ required: true, message: 'Por favor, indique si está reconocido como víctima del conflicto armado.' }]}
          >
            <Select placeholder="Seleccione">
              <Option value={1}>Sí</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="pregunta_4"
            label="¿Actualmente está vinculado o vinculada al proyecto?"
            rules={[{ required: true, message: 'Por favor, indique si está vinculado o vinculada al proyecto.' }]}
          >
            <Select placeholder="Seleccione">
              <Option value={1}>Sí</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="pregunta_5"
            label="¿Requiere apoyo económico para desplazarse al punto de encuentro?"
            rules={[{ required: true, message: 'Por favor, indique si requiere apoyo económico para el desplazamiento.' }]}
          >
            <Select placeholder="Seleccione">
              <Option value={1}>Sí</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="pregunta_7"
            label="¿Cuenta con dispositivos electrónicos como celular, computador o tablet?"
            rules={[{ required: true, message: 'Por favor, indique si cuenta con dispositivos electrónicos.' }]}
          >
            <Select placeholder="Seleccione">
              <Option value={1}>Sí</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="pregunta_6"
            label="¿Cuál es el costo promedio de transporte desde su residencia hasta el lugar de encuentro de la escuela?"
            rules={[{ required: true, message: 'Por favor, indique el costo promedio de transporte.' }]}
          >
            <Input placeholder="Ingrese el valor en pesos colombianos (COP)" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: 20 }}>
            {currentTab > 1 && (
              <Button onClick={handleBack} type="default" icon={<LeftOutlined />}>
                Atrás
              </Button>
            )}
            {currentTab < 4 && (
              <Button onClick={() => preguntasFormRef.current.submit()} type="default" icon={<RightOutlined />}>
                Siguiente
              </Button>
            )}
          </div>
        </Form>
      ),
    }, {
      key: '3',
      label: (
        <span>
          <ExclamationCircleOutlined /> Incidencias
        </span>
      ),
      children: (
        <>
          <Form ref={incidenciasFormRef} onFinish={(values) => handleSubmit('incidencias', values)} layout="vertical">
            <Row justify="center" style={{ marginTop: 20 }}>
              <Col span={8}>
                <Form.Item name="accion_incidencia" label="Acción Incidencia" rules={[{ required: true }]}>
                  <Select
                    placeholder="Acción Incidencia"
                    value={selectedIncidencia}
                    onChange={value => setSelectedIncidencia(value)}
                    style={{ width: 350 }}
                  >
                    {incidencias.map(item => (
                      <Option key={item.oid} value={item.oid}>
                        {item.incidencia}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="accion_social" label="Acción Social" rules={[{ required: true }]}>
                  <Select
                    placeholder="Acción Social"
                    value={selectedSocial}
                    onChange={value => setSelectedSocial(value)}
                    style={{ width: 350 }}
                  >
                    {accionesSociales.map(item => (
                      <Option key={item.oid} value={item.oid}>
                        {item.accion_social}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="accion_problematica" label="Acción Problemática" rules={[{ required: true }]}>
                  <Select
                    placeholder="Acción Problemática"
                    value={selectedProblematica}
                    onChange={value => setSelectedProblematica(value)}
                    style={{ width: 350 }}
                  >
                    {problematicas.map(item => (
                      <Option key={item.oid} value={item.oid}>
                        {item.accion_problematica}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: 20 }}>
            {currentTab > 1 && (
              <Button onClick={handleBack} type="default" icon={<LeftOutlined />}>
                Atrás
              </Button>
            )}
            {currentTab < 4 && (
              <Button onClick={() => incidenciasFormRef.current.submit()} type="default" icon={<RightOutlined />}>
                Siguiente
              </Button>
            )}
          </div>
        </>
      ),
    },
    {
      key: '4',
      label: (
        <span>
          <CheckCircleOutlined /> Asistencia
        </span>
      ),
      children: (
        <>
          <EscuestaAsistencia oidData={oidData} />
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: 20 }}>
            {currentTab > 1 && (
              <Button onClick={handleBack} type="default" icon={<LeftOutlined />}>
                Atrás
              </Button>
            )}
            {currentTab <= 4 && (
              <Button type="primary" icon={<CheckCircleOutlined />} onClick={handleFinish}>
                Finalizar
              </Button>
            )}
            {currentTab < 4 && (
              <Button type="default" icon={<RightOutlined />}>
                Siguiente
              </Button>
            )}
          </div>
        </>
      ),
    }
  ];

  return (
    <div>
      <Tabs activeKey={String(currentTab)} onChange={handleTabChange} items={tabsItems} />
    </div>
  );
};

export default EscuelaDHAAForm; 
