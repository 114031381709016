import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Row, Col, Progress, Flex, Modal, Tooltip, Select, Input } from 'antd';
import { DownloadOutlined, PlusOutlined, EditOutlined, RadarChartOutlined, ClearOutlined, ProfileOutlined } from '@ant-design/icons';
import { getIppta } from '../../services/ippta/get_ippta';
import { selectAppPermissions } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import IpptaForm from './IpptaForm';
import IpptaRadar from './IpptaRadar';
import { getProyectos } from '../../services/ippta/get_dataIppta';
import IpptaExport from './IpptaExport';
import { getIpptaExportable } from '../../services/ippta/get_ippta';
import PlanAccion from '../Ippta/PlanAccion';
const twoColors = { '0%': '#108ee9', '100%': '#87d068', };
const { Option } = Select;

const Ippta = ({ proyectoCodigo }) => {

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setStatus] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showRadar, setShowRadar] = useState(false);
  const [formData, setFormData] = useState(null);
  const [identificacionGrafica, setIdentificacionGrafica] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [identificacionFilter, setIdentificacionFilter] = useState('');
  const [proyectoFilter, setProyectoFilter] = useState('');
  const [proyectos, setProyectos] = useState([]);
  const [oidRegistro, setOidRegistro] = useState(null);
  const [showPlanAccion, setShowPlanAccion] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const appName = 'sistematizacionIppta';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionInsert = permissions.priv_insert === 'Y';
  const permissionExport = permissions.priv_export === 'Y';
  const [isExporting, setIsExporting] = useState(false);


  const handleEdit = (record) => {
    setFormData(record);
    setIsModalVisible(true);
  };

  const handleChart = (record) => {
    setIdentificacionGrafica(record.identificacion);
    setShowRadar(true);
  };

  const handPlanAccion = (record) => {
    setOidRegistro(record.codigo_ippta);
    setShowPlanAccion(true);
  }

  const handleCloseModalPlanAccion = () => {
    setShowPlanAccion(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const proyectosData = await getProyectos();
      setProyectos(proyectosData.data || []);

      const result = await getIppta();
      const combinedData = result.data.map(item => ({
        ...item,
        codigoProyecto: `${item.codigo} - ${item.proyecto}`,
        medicionGeneral: `${Math.round(
          (
            (item.productiva / 35) +
            (item.socioeconomico / 25) +
            (item.ambiental / 30)
          ) / 3 * 100
        )}%`
      }));
      setDataSource(combinedData);
      setOriginalData(combinedData);
      setStatus(null);
    } catch (error) {
      setStatus(error.response ? error.response.status : 500);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (proyectoCodigo) {
      setProyectoFilter(proyectoCodigo);
    }
  }, [proyectoCodigo]);

  if (loading) {
    return <Spin tip="Cargando..." />;
  }

  const showModal = () => {
    setFormData(null);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setFormData(null);
    setIsModalVisible(false);
  };

  const handleCloseModalRadar = () => {
    setShowRadar(false);
  };

  const exportToExcel = async () => {
    setIsExporting(true); // Indicar que la exportación está en proceso
    const response = await getIpptaExportable();
    const data = response.data;

    const filteredData = data.filter(item => {
      return (
        (identificacionFilter === '' || item.identificacion.includes(identificacionFilter)) &&
        (proyectoFilter === '' || item.proyecto === proyectoFilter)
      );
    });

    const updatedData = filteredData.map(item => {
      const calcularSuma = (prefijo, cantidad) => {
        return Array.from({ length: cantidad }, (_, i) => item[`${prefijo}_indicador${i + 1}`] || 0)
          .reduce((acc, curr) => acc + curr, 0);
      };

      const sumaProductivo = calcularSuma('productivo', 7);
      const sumaSocioeconomico = calcularSuma('socioeconomico', 5);
      const sumaAmbiental = calcularSuma('ambiental', 6);

      const porcentajeProductivo = ((sumaProductivo / 35) * 100).toFixed(2) + '%';
      const porcentajeSocioeconomico = ((sumaSocioeconomico / 25) * 100).toFixed(2) + '%';
      const porcentajeAmbiental = ((sumaAmbiental / 30) * 100).toFixed(2) + '%';

      const porcentajeTotal = (
        ((sumaProductivo / 35) + (sumaSocioeconomico / 25) + (sumaAmbiental / 30)) / 3 * 100
      ).toFixed(2) + '%';

      return {
        ...item,
        porcentajeProductivo,
        porcentajeSocioeconomico,
        porcentajeAmbiental,
        porcentajeTotal
      };
    });

    setExportData(updatedData);
    setIsExporting(false); // Terminar la exportación
  };


  const filteredData = dataSource.filter(item => {
    return (
      (identificacionFilter === '' || item.identificacion.includes(identificacionFilter)) &&
      (proyectoFilter === '' || item.codigo === proyectoFilter)
    );
  });

  const handleClearFilters = () => {
    setIdentificacionFilter('');
    setProyectoFilter('');
    setDataSource(originalData);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    }, {
      title: 'Acciones',
      key: 'acciones',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '30px', justifyContent: 'center' }}>
          <Tooltip title="Editar">
            <EditOutlined

              onClick={() => handleEdit(record)}
              style={{ cursor: 'pointer', color: '#1890ff' }}
            />
          </Tooltip>
          <Tooltip title="Plan de acción">
            <ProfileOutlined
              onClick={() => handPlanAccion(record)}
              style={{
                cursor: 'pointer',
                color: record.plan_accion === 1 ? 'green' : 'red', // Cambia el color según el valor
              }}
            />
          </Tooltip>

          <Tooltip title="Ver gráfico">
            <RadarChartOutlined
              onClick={() => handleChart(record)}
              style={{ cursor: 'pointer', color: '#1890ff' }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Proyecto',
      dataIndex: 'codigoProyecto',
      key: 'codigoProyecto',
      responsive: ['md'],
      render: (text) => <span>{text}</span>,
    }, {
      title: 'Identificación',
      dataIndex: 'identificacion',
      key: 'identificacion',
      responsive: ['md'],
    },
    {
      title: 'Participante',
      dataIndex: 'participante',
      key: 'participante',
      responsive: ['sm'],
    },
    {
      title: 'Vereda',
      dataIndex: 'vereda',
      key: 'vereda',
      responsive: ['md'],
    },
    {
      title: 'Municipio',
      dataIndex: 'municipio',
      key: 'municipio',
      responsive: ['lg'],
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
      responsive: ['md'],
    },
    {
      title: 'Medición General',
      dataIndex: 'medicionGeneral',
      key: 'medicionGeneral',
      responsive: ['lg'],
      render: (text) => {
        const percent = parseInt(text.replace('%', ''), 10);
        return (
          <Flex vertical gap="middle" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Progress percent={percent} strokeColor={twoColors} format={percent === 100 ? () => '100%' : () => `${percent}%`} // Muestra el porcentaje
            />
          </Flex>
        );
      },
    }
  ];

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div className="container">
      <React.Fragment>
        <React.Fragment>
          <div className="row mb-2 d-flex align-items-center" >
            <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
              <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                <p>PROYECTO UTF <br /> COL 160 COL</p>
              </div>
              <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
              <h2 className="text-center mb-2">IPPTA</h2>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="input-group shadow-sm">
                    {permissionInsert && (
                      <React.Fragment>
                        <Button
                          type="danger"
                          className="btn btn-primary me-2"
                          size="large"
                          icon={<PlusOutlined />}
                          onClick={showModal} // Abre el modal
                        >
                          Nueva
                        </Button>
                        <IpptaForm
                          visible={isModalVisible}
                          onClose={handleCloseModal}
                          formData={formData}
                          loadData={fetchData}
                        />
                      </React.Fragment>
                    )}
                    {permissionExport && (
                      <Button
                        type="danger"
                        className="btn btn-primary me-2"
                        size="large"
                        onClick={exportToExcel}
                        icon={<DownloadOutlined />}
                        disabled={isExporting}
                      >
                        {isExporting ? 'Excel' : 'Excel'}
                      </Button>
                    )}
                    {isExporting && <IpptaExport data={exportData} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Row gutter={24}>
              <Row gutter={24} style={{ marginBottom: '20px', width: '100%' }}>
                <Col xs={24} sm={4} style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder="Filtrar por Identificación"
                    value={identificacionFilter}
                    onChange={(e) => setIdentificacionFilter(e.target.value)}
                    style={{ height: '40px', padding: '10px' }}
                  />
                </Col>
                <Col xs={24} sm={8} style={{ marginBottom: '10px' }}>
                  <Select
                    style={{ textAlign: 'left', width: '100%', height: '40px' }}
                    placeholder="Filtrar por proyecto"
                    showSearch
                    value={proyectoFilter || undefined}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(value) => setProyectoFilter(value)}
                    dropdownStyle={{ zIndex: 1000 }}
                  >
                    {proyectos?.map(proyecto => (
                      <Option key={proyecto.proyecto_oid} value={proyecto.proyecto_codigo}>
                        {`${proyecto.proyecto_codigo} - ${proyecto.nombre_proyecto}`}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} sm={8} style={{ textAlign: 'left', marginBottom: '10px' }}>
                  <Button
                    type="default"
                    onClick={handleClearFilters}
                    icon={<ClearOutlined />}
                    style={{ height: '40px' }}
                  >
                    Limpiar Filtros
                  </Button>
                </Col>
              </Row>
            </Row>
          </div>
          <div className="participant-count-container">
            <h1 className="participant-count-title">
              Número de Registros: <span className="participant-count-number">{filteredData.length}</span>
            </h1>
          </div>
          <div>
            <Col span={24}>
              <Table
                className="custom-ippta-table"
                dataSource={filteredData}
                columns={columns}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                }}
                onChange={handleTableChange}
              />
            </Col>
          </div>
        </React.Fragment>
      </React.Fragment>
      <Modal
        title={<h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Gráfico IPPTA</h3>}
        visible={showRadar}
        width={1200}
        bodyStyle={{ height: '1100px' }}
        onCancel={handleCloseModalRadar}
        footer={null}
      >
        <IpptaRadar formData={identificacionGrafica} />
      </Modal>
      <Modal
        title={<h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>PLAN DE ACCIÓN PREDIAL PARA LA TRANSICIÓN AGROECOLÓGICA</h3>}
        visible={showPlanAccion}
        width={1200}
        onCancel={handleCloseModalPlanAccion}
        footer={null}
      >
        <PlanAccion oidRegistro={oidRegistro} />
      </Modal>
    </div>
  );
};

export default Ippta;
