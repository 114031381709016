import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, message, Space, Tooltip, Row, Col, Select } from "antd";
import AsistenciaServicio from "../../../../services/Producto_3/Fort_Capacidades/DHAA/cursoFortalecimientoDHAAAsistencia";
import { EditOutlined, DeleteOutlined, PlusOutlined, PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import TipoAsistencia from "./EscuelaDHHATAsistencia";

const { Option } = Select;


const AsistenciaComponent = ({ oidData }) => {
  const [form] = Form.useForm();
  const [asistencias, setAsistencias] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAsistencia, setCurrentAsistencia] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tiposAsistencia, setTiposAsistencia] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (oidData > 0) {
      fetchAsistencias(oidData);
      fetchTiposAsistencia(oidData);
    }
  }, [oidData]);

  const fetchTiposAsistencia = async (oid) => {
    try {
      const data = await AsistenciaServicio.getTipoAsistencia(oid);
      setTiposAsistencia(data);
    } catch (error) {
      message.error('Error al obtener los tipos de asistencia');
    }
  };

  const fetchAsistencias = async (oid) => {
    setLoading(true);
    try {
      const data = await AsistenciaServicio.getAsistenciaById(oid);
      setAsistencias(data);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isEditing) {
        await AsistenciaServicio.updateAsistencia(currentAsistencia.oid, values);
        message.success("Asistencia actualizada exitosamente");
      } else {
        if (oidData) {
          const newValues = {
            ...values,
            general: oidData,
          };
          await AsistenciaServicio.createAsistencia(newValues);
          message.success("Asistencia creada exitosamente");
        }
      }
      form.resetFields();
      setIsEditing(false);
      setCurrentAsistencia(null);
      fetchAsistencias(oidData);
      fetchTiposAsistencia(oidData);
    } catch (error) {
      message.error("Error al guardar la asistencia");
    }
  };

  const handleEdit = (record) => {
    setIsEditing(true);
    setCurrentAsistencia(record);
    const asistenciaSeleccionada = tiposAsistencia.find(
      (tipo) => tipo.oid === record.oid_asistencia
    );
    form.setFieldsValue({
      ...record,
      oid_asistencia: asistenciaSeleccionada ? asistenciaSeleccionada.oid : record.oid_asistencia, // Establece el oid
    });
  };
  const handleDelete = async (oid) => {
    Modal.confirm({
      title: "¿Estás seguro de eliminar esta asistencia?",
      onOk: async () => {
        try {
          await AsistenciaServicio.deleteAsistencia(oid);
          message.success("Asistencia eliminada exitosamente");
          fetchAsistencias(oidData);
        } catch (error) {
          message.error("Error al eliminar la asistencia");
        }
      },
    });
  };

  const handleAddTipoAsistencia = () => {
    setIsEditing(false); 
    setIsModalVisible(true); 
  };

  const handleCloseModal = () => {
    setIsModalVisible(false); 
    fetchTiposAsistencia(oidData);
  };

  const columns = [
    {
      title: "Encuentro",
      dataIndex: "pregunta",
      key: "pregunta",
    },
    {
      title: "Asistencia",
      dataIndex: "respuesta",
      key: "respuesta",
      align: 'center',
      render: (text) => (text === 1 ? 'Sí' : 'No'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      align: 'center',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Editar sesión">
            <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record.oid)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Tooltip title="Crear un nuevo tipo de asistencia">
            <Button
              type="primary"
              onClick={handleAddTipoAsistencia}
              style={{ marginBottom: 16 }}
              icon={<PlusOutlined />}
            >
              Crear nuevo tipo de asistencia
            </Button>
          </Tooltip>

        </Col>
        <Col span={24}>
          <Form form={form} layout="inline" onFinish={handleSubmit}>
            <Form.Item
              label="Tipo de asistencia:"
              name="pregunta"
              rules={[{ required: true, message: "Por favor selecciona un encuentro" }]}
            >
              <Select style={{ width: '300px' }} placeholder="Selecciona el tipo de asistencia">
                {tiposAsistencia.map((tipo) => (
                  <Option key={tipo.oid} value={tipo.oid}>
                    {tipo.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Asistencia"
              name="respuesta"
              rules={[{ required: true, message: "Por favor selecciona una respuesta" }]}
            >
              <Select placeholder="Selecciona una respuesta" style={{ width: 120 }}>
                <Option value={0}>No</Option>
                <Option value={1}>Sí</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Tooltip title={isEditing ? "Actualizar el registro existente" : "Agregar un nuevo registro"}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={isEditing ? <EditOutlined /> : <PlusCircleOutlined />}
                >
                  {isEditing ? "Actualizar" : "Agregar"}
                </Button>
              </Tooltip>
            </Form.Item>
            {isEditing && (
              <Form.Item>
                <Tooltip title="Cancelar la edición">
                  <Button
                    type="default"
                    onClick={() => setIsEditing(false)}
                    icon={<CloseOutlined />}
                  >
                    Cancelar
                  </Button>
                </Tooltip>
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
      <Table
        dataSource={asistencias}
        columns={columns}
        rowKey="oid"
        loading={loading}
        style={{ marginTop: 20 }}
        pagination={{ pageSize: 5 }}
      />
      <Modal
        title="Agregar Tipo de Asistencia"
        visible={isModalVisible} // Controla la visibilidad del modal
        onCancel={handleCloseModal} // Cierra el modal
        footer={null} // No mostrar los botones por defecto del modal
      >
        <TipoAsistencia
          setTiposAsistencia={setTiposAsistencia}
          closeModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default AsistenciaComponent;
