import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import AsistenciaServicio from "../../../../services/Producto_3/Fort_Capacidades/DHAA/cursoFortalecimientoDHAAAsistencia";


const TipoAsistenciaCRUD = () => {
  const [tiposAsistencia, setTiposAsistencia] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTipo, setCurrentTipo] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTiposAsistencia();
  }, []);

  const fetchTiposAsistencia = async () => {
    try {
      const data = await AsistenciaServicio.getTiposAsistencia();
      setTiposAsistencia(data);
    } catch (error) {
      message.error('Error al obtener los tipos de asistencia');
    }
  };

  const handleAdd = () => {
    setCurrentTipo(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    setCurrentTipo(record);
    form.setFieldsValue({ nombre: record.nombre });
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await AsistenciaServicio.removeTipoAsistencia(id);
      message.success('Tipo de asistencia eliminado');
      fetchTiposAsistencia();
    } catch (error) {
      message.error('Error al eliminar el tipo de asistencia');
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (currentTipo) {
        await AsistenciaServicio.modifyTipoAsistencia(currentTipo.oid, values.nombre);
        message.success('Tipo de asistencia actualizado');
      } else {
        await AsistenciaServicio.addTipoAsistencia(values.nombre);
        message.success('Tipo de asistencia agregado');
      }
      setIsModalVisible(false);
      fetchTiposAsistencia();
    } catch (error) {
      message.error('Error al guardar el tipo de asistencia');
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Editar
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.oid)}>
            Eliminar
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Agregar Tipo de Asistencia
      </Button>
      <Table dataSource={tiposAsistencia} columns={columns} rowKey="oid" />
      <Modal
        title={currentTipo ? 'Editar Tipo de Asistencia' : 'Agregar Tipo de Asistencia'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TipoAsistenciaCRUD;
