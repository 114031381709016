import axios from 'axios';
import config from '../../../../config';

const baseUrl = config.baseUrl;

export const getAcompOrg = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompanamiento`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los registros de acompañamiento organizacional:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener los datos. Por favor, intente nuevamente más tarde.');
  }
};

// Obtener todos los registros de acompañamiento
export const getAcompanamientos = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_acompanamientos`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los registros de acompañamiento:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener los datos. Por favor, intente nuevamente más tarde.');
  }
};

// Crear un nuevo registro de acompañamiento
export const createAcompanamiento = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/api/acompa_org_acompanamientos`, data);
    return response.data;
  } catch (error) {
    console.error('Error al crear el registro de acompañamiento:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudo crear el registro. Por favor, intente nuevamente más tarde.');
  }
};

// Actualizar un registro de acompañamiento
export const updateAcompanamiento = async (oid, data) => {
  try {
    const response = await axios.put(`${baseUrl}/api/acompa_org_acompanamientos/${oid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el registro de acompañamiento:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudo actualizar el registro. Por favor, intente nuevamente más tarde.');
  }
};

// Eliminar un registro de acompañamiento
export const deleteAcompanamiento = async (oid) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/acompa_org_acompanamientos/${oid}`);
    return response.data;
  } catch (error) {
    console.error('Error al eliminar el registro de acompañamiento:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudo eliminar el registro. Por favor, intente nuevamente más tarde.');
  }
};

// Obtener proyectos
export const getProyectos = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_proyectos`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los proyectos:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener los proyectos. Por favor, intente nuevamente más tarde.');
  }
};

// Obtener municipios
export const getMunicipios = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_municipios`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los municipios:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener los municipios. Por favor, intente nuevamente más tarde.');
  }
};

// Obtener departamentos
export const getDepartamentos = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_departamentos`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los departamentos:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener los departamentos. Por favor, intente nuevamente más tarde.');
  }
};

// Obtener líneas productivas
export const getLineasProductivas = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_lineas_productivas`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener las líneas productivas:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener las líneas productivas. Por favor, intente nuevamente más tarde.');
  }
};

// Obtener SubRegiones
export const getSubRegiones = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_sub_region`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener las sub regiones:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener las sub regiones. Por favor, intente nuevamente más tarde.');
  }
};

export const getProyectAll = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/acompa_org_proyectos_all`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los proyectos:', {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
    throw new Error('No se pudieron obtener los proyectos. Por favor, intente nuevamente más tarde.');
  }
};

export const upsertAcompOrg = async (updatedRecord) => {
  try {
    const response = await axios.post(`${baseUrl}/api/acompOrg`, updatedRecord);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el registro:', error);
    throw new Error('No se pudo actualizar el registro. Intenta nuevamente.');
  }
};