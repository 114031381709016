import React, { useEffect, useState } from 'react';
import { Button, Drawer, Space, Tree, message, Tooltip, Popconfirm, Upload, Spin, Radio } from 'antd';
import { FolderOpenOutlined, CloudUploadOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload, faTrash, faCloudUploadAlt, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

// Importaciones de servicios y funciones internas
import { postReports } from '../../services/create_tree/get_tree';
import { deleteFile } from '../../services/create_tree/delete_tree_file';
import { deleteDir } from '../../services/create_tree/delete_tree_dir';
import { getTreeFile } from '../../services/create_tree/get_tree_file';
import { upload_tree_file } from '../../services/create_tree/upload_tree_file';
import { upload_tree_dir } from '../../services/create_tree/upload_tree_dir';
import { view_tree_file } from '../../services/create_tree/view_tree_file';

// Importaciones de configuraciones y estilos
import config from '../../config';
import './ReportTree.css';
import { selectAppPermissions } from '../../store/auth/selectors';

  const { DirectoryTree } = Tree;
  const ReportTree = ({ open, onClose, reportData }) => {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [, setFilesInProcess] = useState(0);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [data, setData] = useState([]);

  // Permisos
  const appName = 'reportes'; // El nombre de la aplicación actual
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionDelete = permissions.priv_delete === 'Y';
  const permissionExport = permissions.priv_export === 'Y';
  const permissionInsert = permissions.priv_insert === 'Y';

  useEffect(() => {
    if (reportData.nombre_modulo) {  // Verifica que nombre_modulo tenga un valor
      refreshData();
    } else {

    }
  }, [reportData.nombre_modulo]);  // Dependencia en nombre_modulo

  useEffect(() => {
    if (treeData.length > 0) {
      const initialExpandedKeys = treeData.map(node => node.key);
      setExpandedKeys(initialExpandedKeys);
    }
  }, [treeData]);
 
  const refreshData = async () => {
    try {
      const directoryPath = config.baseDirUrl;
      const { data } = await postReports({ directoryPath, codigoProyecto: reportData.nombre_modulo });
      setData(data);
      const transformedData = transformData(data, handleDeleteFile, handleDownload, getUploadProps, handleView, getUploadFolderProps);
      setTreeData(transformedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Funcion para limpiar el nombre del archivo 
  const processFileName = (fileName, maxLength) => {
    // Primero limpiamos el nombre del archivo para quitar el hash
    const cleanedFileName = cleanFileName(fileName);
    // Luego truncamos el nombre si es necesario
    return truncateTextName(cleanedFileName, maxLength);
  };

  //Funcion para truncar el nombre del archivo
  const cleanFileName = (encodedFileName) => {
    // Primero decodifica el nombre del archivo
    const decodedFileName = decodeURIComponent(encodedFileName);

    // Luego aplica la lógica de limpiar el nombre del archivo
    const index = decodedFileName.indexOf('-');

    return index !== -1 ? decodedFileName.substring(index + 1) : decodedFileName;
  };

  //Funcion para truncar el texto del nombre
  const truncateTextName = (text, maxLength) => {
    if (!text || typeof text !== 'string') {
      return ''; // Devuelve una cadena vacía si text es undefined, null, o no es una cadena
    }
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  //Funcion para obtener la extension del archivo
  const getFileExtension = (filePath) => {
    return filePath.split('.').pop().toLowerCase();
  };

  //Funcion para verificar si la extension es visualizable
  const isViewableExtension = (extension) => {
    const viewableExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    return viewableExtensions.includes(extension);
  };

  //Funcion para manejar la visualizacion del archivo
  const handleView = async (node) => {
    setSpinnerVisible(true); // Mostrar spinner cuando se inicie la visualización

    const path = node.path;
    if (!path) {
      console.error('No se proporcionó la ruta del archivo');
      message.error('No se proporcionó la ruta del archivo.', 5);
      setSpinnerVisible(false); // Ocultar spinner si no se proporciona la ruta
      return;
    }

    const extension = getFileExtension(path);

    if (!isViewableExtension(extension)) {
      console.error('Tipo de archivo no soportado para visualización');
      message.error('El tipo de archivo no puede ser visualizado en el navegador.', 5);
      setSpinnerVisible(false); // Ocultar spinner si el tipo de archivo no es soportado
      return;
    }

    try {
      const result = await view_tree_file(path);

      if (result.status !== 200) {
        console.error('Error al visualizar el archivo:', result.message);
        message.error('Error al descargar el archivo.', 5);
      } else {
        message.success('Archivo abierto correctamente.');
      }
    } catch (error) {
      console.error('Error al visualizar el archivo:', error.message || error);
      message.error('Error al descargar el archivo.', 5);
    } finally {
      setSpinnerVisible(false); // Ocultar spinner cuando la visualización se complete
    }
  };

  //Funcion para descargar el archivo
  const handleDownload = async (node) => {
    setSpinnerVisible(true);
    try {
      const result = await getTreeFile(node);
      if (result) {
        message.success('Archivo descargado correctamente.');
      } else {
        message.error('Error al descargar el archivo.', 5);
      }
    } catch (error) {
      message.error('Error al descargar el archivo.', 5);
      console.error('Error al descargar el archivo:', error);
    } finally {
      setSpinnerVisible(false); // Ocultar spinner cuando la descarga se complete
    }
  };

  //Funcion para transformar los datos
  const transformData = (data, handleDeleteFile, handleDownload, getUploadProps, handleView, getUploadFolderProps) => {
    const formatNode = (node, level = 0) => {
      return {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
              <Tooltip title={processFileName((node.name), 50)}>
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {processFileName((node.name), 50)}
                </span>
              </Tooltip>
            </div>
            {!node.isLeaf && node.fileCount !== undefined && (
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                ({node.fileCount} {node.fileCount === 1 ? 'archivo' : 'archivos'})
              </span>
            )}
            <div style={{ marginLeft: 'auto' }}>
              {node.isLeaf && (
                <>
                  <Tooltip title="Ver">
                    <button
                      className="btn-primary-lis"
                      style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}

                      onClick={() => handleView(node)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </Tooltip>
                  {permissionExport && [
                    <span style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                    <Tooltip title="Descargar Archivo">
                      <button
                        className="btn-primary-lis"
                        style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                        onClick={() => handleDownload(node)}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </button>
                    </Tooltip>
                  ]}

                  {permissionDelete && [
                    <span style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                    <Tooltip title="Eliminar">
                      <Popconfirm
                        title='Eliminación de archivo'
                        description="¿Segur@ que quieres eliminar este archivo?"
                        onConfirm={() => handleDeleteFile(node)}
                        okText="Sí"
                        cancelText="No"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      >
                        <button
                          className="btn-primary-lis"
                          style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </Popconfirm>
                    </Tooltip>
                  ]}
                </>
              )}
              {!node.isLeaf && (
                <>
                  <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                    {permissionExport && [<Tooltip title="Descargar Carpeta">
                      <button
                        className="btn-primary-lis"
                        style={{ background: 'none', border: 'none', color: 'inherit', font: 'inherit', cursor: 'pointer' }}
                        onClick={() => handleDownload(node)}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </button>
                    </Tooltip>,
                    <span style={{ fontSize: '16px', paddingRight: '0px', paddingLeft: '5px' }}> | </span>]}


                    {permissionInsert && (
                      <>
                        <Tooltip title="Subir Archivo">
                          <Upload {...getUploadProps(node)}>
                            <Button
                              className="btn-primary-lis upload-button"
                              style={{ background: 'none', border: 'none', font: 'inherit', cursor: 'pointer' }}
                              icon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
                            />
                          </Upload>
                        </Tooltip>


                        <span style={{ fontSize: '16px', paddingRight: '0px', paddingLeft: '34px' }}> | </span>


                        <Tooltip title="Subir Carpetas">
                          <Upload {...getUploadFolderProps(node)} directory>
                            <Button
                              className="btn-primary-lis"
                              style={{ background: 'none', border: 'none', color: 'inherit', margin: '0 -2px', font: 'inherit', cursor: 'pointer' }}
                              icon={<FontAwesomeIcon icon={faFolderPlus} />}
                            />
                          </Upload>
                        </Tooltip>
                      </>
                    )}


                    <span style={{ fontSize: '16px', paddingRight: '0px', paddingLeft: '27px' }}> | </span>
                    {permissionDelete && (
                      <Tooltip title="Eliminar" >
                        <Popconfirm
                          title='Eliminación de archivo'
                          description="¿Segur@ que quieres eliminar este archivo?"
                          onConfirm={() => handleDeleteFile(node)}
                          okText="Sí"
                          cancelText="No"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        >
                          <button
                            className="btn-primary-lis"
                            style={{ background: 'none', border: 'none', color: 'inherit', margin: '0 4px', font: 'inherit', cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </Popconfirm>
                      </Tooltip>
                    )}

                  </div>
                </>
              )}
            </div>
          </div>
        ),
        key: node.key,
        isLeaf: node.isLeaf,
        pathFromClient: node.pathFromClient || node.path, // Asigna pathFromClient si está presente, sino usa path
        children: node.children ? node.children.map(child => formatNode(child, level + 1)).filter(Boolean) : [],
      };
    };

    return data.map(node => formatNode(node)).filter(Boolean);

  };

  //Funcion para obtener las propiedades de subir archivo
  const getUploadProps = (node) => ({
    name: 'file',
    multiple: true,
    showUploadList: false,
    customRequest: async (info) => {
      setSpinnerVisible(true); // Mostrar spinner cuando se inicie la carga
      const { file } = info;
      // Verificar el tamaño del archivo
      const maxSize = 100 * 1024 * 1024; // 300 MB en bytes
      if (file.size > maxSize) {
        message.error(`El archivo "${file.name}" es demasiado pesado para cargar. El límite es 100 MB.`, 5);
        setSpinnerVisible(false); // Ocultar spinner si el archivo es demasiado pesado
        return;
      }

      if (!node.path) {
        message.error('Debe seleccionar un elemento del árbol para cargar archivos.', 5);
        setSpinnerVisible(false); // Ocultar spinner si no hay una ruta válida
        return;
      }

      try {
        const uploadedBy = 'defaultUser'; // Puedes obtener esto de otro lugar si es necesario
        const response = await upload_tree_file(node.path, file, uploadedBy);

        if (response.status === 'success') {
          file.serverFileName = response.serverFileName;
          refreshData();
          message.success(`Archivo cargado correctamente: ${file.name}`);
        } else {
          message.error(`Error en la carga: ${file.name}`, 5);
        }
      } catch (error) {
        message.error(`Error al cargar el archivo:: ${error.message}`, 5);
      } finally {
        setSpinnerVisible(false); // Ocultar spinner cuando la carga se complete
      }
    },
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  //Funcion para eliminar archivo
  const handleDeleteFile = async (node) => {
    setSpinnerVisible(true); // Mostrar spinner cuando se inicie la eliminación
    const fullPath = node.path ||
      (node.pathFromClient && node.serverFileName ?
        `${node.pathFromClient}/${node.serverFileName}` :
        null);
    if (!fullPath) {
      message.error('No se encontró el nombre del archivo en el servidor.', 5);
      setSpinnerVisible(false); // Ocultar spinner si no se encuentra el archivo
      return;
    }
    try {
      let result;
      if (node.isLeaf) {
        // Eliminar archivo
        result = await deleteFile(fullPath);
      } else {
        // Eliminar carpeta
        result = await deleteDir(fullPath);
      }

      if (result) {
        message.success(node.isLeaf ? 'Archivo eliminado correctamente.' : 'Carpeta eliminada correctamente.');
        // Actualiza el árbol después de eliminar el archivo o carpeta
        refreshData();
      } else {
        message.error(node.isLeaf ? 'Error al eliminar el archivo.' : 'Error al eliminar la carpeta.', 5);
      }
    } catch (error) {
      message.error(node.isLeaf ? 'Error al eliminar el archivo.' : 'Error al eliminar la carpeta.', 5);
      console.error('Error al eliminar:', error);
    } finally {
      setSpinnerVisible(false); // Ocultar spinner cuando la eliminación se complete
    }
  };

  //Funcion para descargar carpeta
  const download_folder = async (nodo) => {
    setSpinnerVisible(true); // Mostrar spinner cuando se inicie la descarga
    try {
      const result = await getTreeFile(nodo);
      if (result) {
        message.success('Archivo descargado correctamente.');
      } else {
        message.error('Error al descargar el archivo.', 5);
      }
    } catch (error) {
      message.error('Error al descargar el archivo.', 5);
      console.error('Error al descargar el archivo:', error);
    } finally {
      setSpinnerVisible(false); // Ocultar spinner cuando la descarga se complete
    }
  };

  //Funcion para obtener las propiedades de subir carpeta
  const getUploadFolderProps = (node) => ({
    name: 'file',
    multiple: true,
    showUploadList: false,
    customRequest: async (info) => {
      setFilesInProcess(prev => {
        const newFilesInProcess = prev + 1;
        if (newFilesInProcess === 1) {
          setSpinnerVisible(true);
        }
        return newFilesInProcess;
      });

      const { file } = info;
      const maxSize = 100 * 1024 * 1024; // 100 MB
      if (file.size > maxSize) {
        message.error(`El archivo "${file.name}" es demasiado grande.`, 5);
        setFilesInProcess(prev => {
          const newFilesInProcess = prev - 1;
          if (newFilesInProcess === 0) {
            setSpinnerVisible(false);
          }
          return newFilesInProcess;
        });
        return;
      }

      let varpath = '';
      if (!node.path) {
        if (!node.nombre_modulo) {
          message.error('Debe seleccionar un elemento del árbol para cargar archivos.', 5);
          setFilesInProcess(prev => {
            const newFilesInProcess = prev - 1;
            if (newFilesInProcess === 0) {
              setSpinnerVisible(false);
            }
            return newFilesInProcess;
          });
          return;
        } else {
          varpath = node.nombre_modulo;
        }
      } else {
        varpath = node.path;
      }

      try {
        const fileData = {
          file: file,
          webkitRelativePath: file.webkitRelativePath || '',
        };

        // Obtén la lista de carpetas que podrían estar vacías
        const directories = getDirectoriesFromPath(file.webkitRelativePath);

        // Construir FormData
        const formData = new FormData();
        formData.append('file', file);
        formData.append('pathFromClient', varpath);
        formData.append('webkitRelativePath', file.webkitRelativePath || '');
        formData.append('directories', JSON.stringify(directories));

        // Mostrar contenido de FormData en consola
        //logFormData(formData);

        // Enviar la solicitud al backend

        const response = await upload_tree_dir(varpath, [fileData], directories);

        if (response.status === 'success') {
          file.serverFileName = response.serverFileName;
        } else {
          message.error(`Error en la carga: ${file.name}`, 5);
        }
      } catch (error) {
        console.error('Error al cargar la carpeta:', error);
        message.error(`Error al cargar la carpeta: ${error.message}`, 5);
      } finally {
        setFilesInProcess(prev => {
          const newFilesInProcess = prev - 1;
          if (newFilesInProcess === 0) {
            message.success('Carga de archivos completada.');
            setSpinnerVisible(false);
            refreshData();
          }
          return newFilesInProcess;
        });
      }
    },
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  // Función para obtener las carpetas desde la ruta del archivo
  const getDirectoriesFromPath = (filePath) => {
    const parts = filePath.split('/');
    const directories = [];
    for (let i = 0; i < parts.length - 1; i++) {
      directories.push(parts.slice(0, i + 1).join('/'));
    }
    return directories;
  };


  //Funcion para filtrar carpetas vacias
  function filterEmptyFolders(data) {
    return data.reduce((acc, item) => {
      if (item.type === 'folder') {
        const filteredChildren = filterEmptyFolders(item.children || []);
        if (filteredChildren.length > 0 || item.fileCount > 0) {
          acc.push({ ...item, children: filteredChildren });
        }
      } else if (item.type === 'file') {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  //Funcion para encontrar carpetas vacias
  function findEmptyFolders(data) {
    function traverseFolders(folder) {
      // Si la carpeta no tiene archivos y no tiene subcarpetas, se conserva
      if (folder.fileCount === 0 && (!folder.children || folder.children.length === 0)) {
        return folder;
      }

      // Si la carpeta tiene subcarpetas, recorrerlas
      if (folder.children) {
        const filteredChildren = folder.children
          .map(child => traverseFolders(child))
          .filter(child => child !== null);  // Filtra los nulos que no son carpetas vacías

        // Si no quedan subcarpetas después del filtrado, y la carpeta actual está vacía
        if (filteredChildren.length === 0 && folder.fileCount === 0) {
          return folder;
        }

        // Asignar las subcarpetas filtradas a la carpeta actual
        folder.children = filteredChildren;
      }

      // Si la carpeta tiene subcarpetas vacías, se conserva
      if (folder.children && folder.children.length > 0) {
        return folder;
      }

      // Si no se cumplen las condiciones, no se devuelve nada
      return null;
    }

    // Aplicar la función a cada elemento del nivel raíz
    return data.map(folder => traverseFolders(folder)).filter(folder => folder !== null);
  }


  //Funcion para manejar el cambio de filtro
  const handleFilterChange = (value) => {
    if (!Array.isArray(data)) {
      console.error("Datos no es un array", data);
      return;
    }
    setFilterType(value);

    let filteredData;
    switch (value) {
      case "empty":
        filteredData = findEmptyFolders(JSON.parse(JSON.stringify(data)));
        break;
      case "withFiles":
        filteredData = filterEmptyFolders(JSON.parse(JSON.stringify(data)));
        break;
      default:
        filteredData = JSON.parse(JSON.stringify(data));
    }

    const transformedData = transformData(
      filteredData,
      handleDeleteFile,
      handleDownload,
      getUploadProps,
      handleView,
      getUploadFolderProps
    );

    setTreeData(transformedData);
  };


  return (
    <Drawer
      title={`Verificar ${reportData.nombre_modulo}`}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}
      extra={

        <Space>
          {permissionInsert && (

            <Tooltip title="Subir Carpetas">
              <Upload style={{ paddingLeft: '30px' }}
                {...getUploadFolderProps(reportData)} directory>
                <div style={{ paddingLeft: '135px' }}>
                  <Button
                    style={{ background: '#fff', color: '#e5ac1a' }}
                    icon={<FolderOpenOutlined />}
                    loading={loading}
                  >
                    Importar
                  </Button></div>
              </Upload>
            </Tooltip>
          )}
          {permissionExport && (
            <div style={{ paddingRight: '115px' }}>
              <Tooltip title="Descargar Carpeta">
                <Button
                  type="primary"
                  style={{ marginRight: '5px' }}
                  icon={<CloudUploadOutlined />}
                  onClick={() => download_folder({ path: reportData })}
                  loading={loading}
                >
                  Descargar
                </Button>
              </Tooltip></div>
          )}
          <Tooltip title="Cerrar">
            <Button
              danger
              onClick={onClose}
              icon={<CloseOutlined />}
            >
              Cancelar
            </Button></Tooltip>
        </Space>
      }
    >
      {spinnerVisible && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Spin size="large" style={{ paddingLeft: '20px' }}
          />
        </div>
      )}

      <h6><strong>Estado:</strong> {reportData.estado}</h6>
      <h6><strong>Periodo:</strong> {reportData.periodo}</h6>
      <Radio.Group value={filterType} onChange={(e) => handleFilterChange(e.target.value)}>
        <Radio.Button value="all">Todos</Radio.Button>
        <Radio.Button value="empty">Carpetas Vacías</Radio.Button>
        <Radio.Button value="withFiles">Carpetas con Archivos</Radio.Button>
      </Radio.Group>



      <DirectoryTree
        className="custom-tree"
        multiple
        defaultExpandAll={false}
        expandedKeys={expandedKeys}
        onExpand={(keys) => setExpandedKeys(keys)}
        treeData={treeData}
      />
    </Drawer>
  );
};

export default ReportTree;