import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tree, Button, Spin,  Descriptions, Modal, Layout, Alert, Input, Table, Tooltip } from 'antd';
import {  FileZipOutlined, SearchOutlined,  FileExclamationOutlined, FolderOutlined, ReloadOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import './FileManagerPOA.css';
import config from '../../config';
import PoaPreliminarDetalle from './PoaPreliminarDetalle';

const { Content, Sider } = Layout;
const { DirectoryTree } = Tree;
const baseUrl = config.baseUrl;

const truncateText = (text, maxLength) => {
    if (!text || typeof text !== 'string') {
        return '';
    }
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};

const cleanFileName = (encodedFileName) => {
    const decodedFileName = decodeURIComponent(encodedFileName);
    const match = decodedFileName.match(/^(\d+)-(.+)$/); 
    return match ? match[2] : decodedFileName; 
};




const FileManagerPOA = (codigo_tarea) => {
    const [codTarea, setCodTarea] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [treeDataFull, setTreeDataFull] = useState([]);
    const [treeDataTable, setTreeDataTable] = useState([]);
    const [detalleDataTree, setDetalleDataTree] = useState([]);
    const [, settreeFilesData] = useState([]);
    const tmpCodigo = String(codigo_tarea.codigo_tarea.codigo_tarea).replace(/\./g, '');
    const codigo_proyecto_busqueda = 'tarea_' + tmpCodigo;
    const [loading, setLoading] = useState(true);
    const [expandAll,] = useState(true);
    const [, settituloNameProyecto] = useState('');
    const nameproyecto = "Tarea POA";
    const [showWarning, setShowWarning] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [, setProyectos] = useState([]);
    const [, setSearchValueCod] = useState('');
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const inputRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [tituloProyecto, settituloProyecto] = useState('');

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
    };

    useEffect(() => {
        if (isModalVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isModalVisible]);

    useEffect(() => {
        const fetchData = async () => {
            if (!codigo_proyecto_busqueda) {
                setLoading(false);
                setShowWarning(true);
                settituloNameProyecto("FAO - UTF COL 160 COL")
                return;
            }
            settituloNameProyecto(`${codigo_proyecto_busqueda} ${nameproyecto}`);
            try {
                const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
                const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
                const response = await axios.get(`${baseUrl}/api/directorytreepoa?codigActividad=${codActividadParametro}&codigoTarea=${codigo_proyecto_busqueda}`);
                const data = response.data;
                const indicador = codigo_tarea.codigo_tarea.key;
                const getIndicadorChildren = (nodes) => {
                    return nodes.flatMap(node => {
                        if (node.typeS === 'indicador' && node.name === indicador) {
                            return node.children;
                        } else if (node.children && node.children.length > 0) {
                            return getIndicadorChildren(node.children);
                        }
                        return [];
                    });
                };
                const filteredData = getIndicadorChildren(data);
                setTreeDataFull(filteredData);
                const filterLimitData = limitChildrenToThreeLevels(filteredData);
                setTreeData(filterLimitData);
                setExpandedKeys(keys => {
                    if (!keys.includes('0')) {
                        return [...keys, '0'];
                    }
                    return keys;
                });
                if (!expandAll && data.length > 0) {
                    const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                    setExpandedKeys(initialKeysToExpand);
                }
            } catch (error) {
                console.error('Error fetching file tree:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [codigo_proyecto_busqueda, expandAll]);


    const limitChildrenToThreeLevels = (data, level = 1) => {
        return data.map(item => {
            const newItem = { ...item };
            if (level < 3 && newItem.children && newItem.children.length > 0) {
                newItem.children = limitChildrenToThreeLevels(newItem.children, level + 1);
            } else {
                delete newItem.children;
            }
            return newItem;
        });
    };

    useEffect(() => {
        const fetchProyectos = async () => {
            setCodTarea(codigo_tarea);
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/arbolProyectos`);
                const data = response.data.data;
                setProyectos(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching proyectos:', error);
                setLoading(false);
            }
        };

        fetchProyectos();
    }, [codigo_tarea, setLoading]);

    const searchInTree = (node, searchTerm, expandedKeys) => {
        let foundMatch = false;
        if (node.viewName.toLowerCase().includes(searchTerm.toLowerCase())) {
            node.highlighted = true;
            foundMatch = true;
            node.title = (
                <span>
                    {node.viewName.replace(
                        new RegExp(`(${searchTerm})`, 'gi'),
                        (text, match) => (
                            <span style={{ backgroundColor: 'yellow', color: '#AAA' }}>{match}</span>
                        )
                    )}
                </span>
            );
        } else {
            node.highlighted = false;
        }
        if (node.children && node.children.length > 0) {
            node.children.forEach(childNode => {
                if (searchInTree(childNode, searchTerm, expandedKeys)) {
                    expandedKeys.push(node.key);
                    foundMatch = true;
                }
            });
        }
        return foundMatch;
    };

    const onChange = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (treeData.length > 0) {
            const emptyFolders = filterEmptyFolders(treeData);
            settreeFilesData(emptyFolders);
            setExpandedKeys(prevExpandedKeys => {
                const nodeZeroKey = treeData[0]?.key;
                const updatedExpandedKeys = prevExpandedKeys.includes(nodeZeroKey)
                    ? prevExpandedKeys
                    : [...prevExpandedKeys, nodeZeroKey];
                return updatedExpandedKeys;
            });
        }
    }, [treeData]);

    const onClickBuscar = () => {
        if (searchValue.trim() === '') {
            return;
        }
        const clonedTreeData = cloneDeep(treeData);
        const newExpandedKeys = [];
        clonedTreeData.forEach(node => searchInTree(node, searchValue, newExpandedKeys));
        const nodeZeroKey = treeData[0]?.key;
        if (nodeZeroKey && !newExpandedKeys.includes(nodeZeroKey)) {
            newExpandedKeys.push(nodeZeroKey);
        }
        setTreeData(clonedTreeData);
        setExpandedKeys(newExpandedKeys);
    };

    const findNodeUpToThreeLevels = (data, key, level = 1) => {
        for (const item of data) {
            if (item.key === key) {
                return item;
            }
            if (level < 3 && item.children) {
                const found = findNodeUpToThreeLevels(item.children, key, level + 1);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const handleSelect = async (selectedKeys) => {
        if (selectedKeys.length === 0) {
            return;
        }
        const selectedKey = selectedKeys[0];
        const selectedNode = findNodeUpToThreeLevels(treeDataFull, selectedKey);
        if (selectedNode.level > 3) {
            settituloProyecto(selectedNode.nameFather);
            if (selectedNode) {
                setTreeDataTable(selectedNode.children || []);
            } 
        } else {
            setTreeDataTable([]);
        }
    };

    const onLimpiarBusqueda = async () => {
        setActiveButton(null);
        setSearchValue('');
        setSearchValueCod('');
        setLoading(true);
        try {
            const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
            const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
            const response = await axios.get(`${baseUrl}/api/directorytreepoa?codigActividad=${codActividadParametro}&codigoTarea=${codigo_proyecto_busqueda}`);
            const data = response.data;
            const getIndicadorChildren = (nodes) => {
                return nodes.flatMap(node => {
                    if (node.typeS === 'indicador') {
                        return node.children;
                    } else if (node.children && node.children.length > 0) {
                        return getIndicadorChildren(node.children);
                    }
                    return [];
                });
            };
            const filteredData = getIndicadorChildren(data);
            const filterLimitData = limitChildrenToThreeLevels(filteredData);
            setTreeData(filterLimitData);
            setExpandedKeys([filterLimitData[0]?.key]);
        } catch (error) {
            console.error('Error fetching file tree:', error);
        } finally {
            setLoading(false);
        }
    };

    const titleRenderer = (node) => {
        const maxLength = 20;
        let decodedFileName;
        let nombre;
        let truncatedTitle;
        if (node.type === 'folder') {
            nombre = node.viewName;
            truncatedTitle = truncateText(nombre, maxLength);;
        } else {
            decodedFileName = decodeURIComponent(node.viewName);
            nombre = cleanFileName(decodedFileName);
            truncatedTitle = truncateText(nombre, maxLength);
        }
        return (
            <Tooltip title={nombre} placement="right" style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <span
                    style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                    className={node.highlighted ? 'highlightedNode' : ''}
                >
                    {truncatedTitle}
                </span>
            </Tooltip>
        );
    };

    const filterEmptyFolders = (treeData) => {
        const isEmptyFinalFolder = (node) => {
            if (node.type !== 'folder') {
                return false;
            }
            if (!node.children || node.children.length === 0) {
                return true;
            }
            const allSubfoldersEmpty = node.children.every(child => isEmptyFinalFolder(child));
            return allSubfoldersEmpty;
        };
        const filterTree = (nodes) => {
            return nodes
                .map(node => {
                    const filteredChildren = node.children ? filterTree(node.children) : [];
                    if (node.type === 'folder') {
                        const shouldKeep = isEmptyFinalFolder(node) || filteredChildren.length > 0;
                        return shouldKeep ? { ...node, children: filteredChildren } : null;
                    }
                    return null;
                })
                .filter(node => node !== null);
        };
        const filteredTreeData = filterTree(treeData);
        return filteredTreeData;
    };

    const filterFolders = () => {
        const hasFiles = (node) => {
            if (node.type === 'file') return true;
            if (node.children && node.children.length > 0) {
                return node.children.some(child => hasFiles(child));
            }
            return false;
        };
        const stack = [...treeData];
        const foldersToRemove = new Set();
        while (stack.length > 0) {
            const node = stack.pop();
            if (node.type === 'folder') {
                if (node.children) {
                    node.children.forEach(child => stack.push(child));
                    if (!hasFiles(node)) {
                        foldersToRemove.add(node);
                    }
                }
            }
        }
        const filterTree = (nodes) => {
            return nodes
                .map(node => {
                    const filteredChildren = node.children ? filterTree(node.children) : [];
                    if (node.type === 'folder') {
                        const shouldKeep = node === treeData[0] || !foldersToRemove.has(node);
                        return shouldKeep ? { ...node, children: filteredChildren } : null;
                    }
                    return node;
                })
                .filter(node => node !== null);
        };
        const filteredTreeData = filterTree(treeData);
        return filteredTreeData;
    };

    const handleFilterEmptyFolders = () => {
        const filteredData = filterEmptyFolders(treeData);
        setTreeData(filteredData);
        setActiveButton('emptyFolders');
    };

    const handleFilterFolders = () => {
        const filteredData = filterFolders(treeData);
        setTreeData(filteredData);
        setActiveButton('folders');
    };

    const treeContainerStyle = {
        height: '500px',
        overflowY: 'auto',
        border: '1px solid #d9d9d9',
        padding: '8px',
        boxSizing: 'border-box'
    };

    const { codActividad, nameActividad, codigo_tarea: codigoTarea, tarea, indicador } = codigo_tarea.codigo_tarea;

    let medioVerificacion = "Medio de Verificación";

    const handleFolderClick = (project) => {
        const formattedProject = {
            ...project,
            children: Array.isArray(project.children) ? project.children : [],
        };
        setDetalleDataTree(formattedProject);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedProject(null);
        setDetalleDataTree([]);
    };

    return (
        <Layout>
            {showWarning && (
                <Alert
                    message="Advertencia"
                    description="Debe seleccionar un código de tarea para ver los medios de verificación."
                    type="warning"
                    showIcon
                    className="mb-3"
                />
            )}
            {loading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container-fluid py-0 my-2" style={{ marginBottom: '10px !important' }}>
                    <Descriptions bordered column={1} style={{ padding: '0 0 0 10px !important' }}>
                        <Descriptions.Item label="Actividad">
                            {codActividad} - {nameActividad}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tarea">
                            {codigoTarea} - {tarea}
                        </Descriptions.Item>
                        <Descriptions.Item label="Indicador">
                            {indicador}
                        </Descriptions.Item>
                        <Descriptions.Item label="Medio de verificación">
                            <div>
                                {typeof medioVerificacion === 'string'
                                    ? medioVerificacion
                                    : JSON.stringify(medioVerificacion)}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Layout>
                        <Sider width={300} className="site-layout-background">
                            <div style={{ marginBottom: 8 }}>
                                <Input
                                    placeholder="Buscar"
                                    value={searchValue}
                                    onChange={onChange}
                                    style={{ width: 300 }}
                                    suffix={
                                        <>
                                            <Tooltip title="Buscar archivos por nombre" placement="top">
                                                <Button
                                                    type="primary"
                                                    icon={<SearchOutlined />}
                                                    onClick={onClickBuscar}
                                                    style={{ marginRight: 8 }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Restablecer filtros de carpetas" placement="top">
                                                <Button
                                                    icon={<ReloadOutlined />}
                                                    onClick={onLimpiarBusqueda}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Filtrar carpetas sin archivos" placement="top">
                                                <Button
                                                    icon={<FileExclamationOutlined />}
                                                    onClick={handleFilterEmptyFolders}
                                                    style={{
                                                        backgroundColor: activeButton === 'emptyFolders' ? 'lightblue' : 'initial',
                                                        borderColor: activeButton === 'emptyFolders' ? 'blue' : 'initial',
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Filtrar carpetas con archivos" placement="top">
                                                <Button
                                                    icon={<FileZipOutlined />}
                                                    onClick={handleFilterFolders}
                                                    style={{
                                                        backgroundColor: activeButton === 'folders' ? 'lightblue' : 'initial',
                                                        borderColor: activeButton === 'folders' ? 'blue' : 'initial',
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                />
                            </div>
                            <div style={treeContainerStyle}>
                                <DirectoryTree
                                    treeData={treeData}
                                    expandedKeys={expandedKeys}
                                    onExpand={onExpand}
                                    onSelect={handleSelect}
                                    autoExpandParent={autoExpandParent}
                                    titleRender={titleRenderer}
                                    showLine={true}
                                    multiple
                                    defaultExpandAll={true}
                                />
                            </div>
                        </Sider>
                        <Layout style={{ padding: '0px 24px 24px' }}>
                            <Content className="site-layout-background" style={{ padding: 0, margin: 0, minHeight: 280 }}>
                                <div style={{ textAlign: 'center', padding: '0px' }}>
                                    <p className="titulo_proyecto display-4">Bienvenid@</p>
                                    <div>
                                        {treeDataTable && treeDataTable.length > 0 ? (
                                            <Table
                                                dataSource={treeDataTable}
                                                scroll={{ y: 400 }}
                                                pagination={{ pageSize: 15 }}
                                                columns={[
                                                    {
                                                        title: 'No.',
                                                        key: 'contador',
                                                        width: 80,
                                                        align: 'center',
                                                    },
                                                    {
                                                        title: 'Nombre del proyecto',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                        width: 300,
                                                    },
                                                    {
                                                        title: 'Número de archivos',
                                                        dataIndex: 'fileCount',
                                                        key: 'fileCount',
                                                        width: 300,
                                                        align: 'center',
                                                        render: (fileCount, record) => (
                                                            !record.isLeaf ? <span>{fileCount}</span> : <span>-</span>
                                                        ),
                                                    },
                                                    {
                                                        title: 'Carpeta',
                                                        key: 'icon',
                                                        width: 100,
                                                        align: 'center',
                                                        render: (text, record) =>
                                                            !record.isLeaf ? (
                                                                <Tooltip title="Abrir carpeta">
                                                                    <FolderOutlined
                                                                        style={{ fontSize: '24px', color: '#FFD700', cursor: 'pointer' }}
                                                                        onClick={() => handleFolderClick(record)}
                                                                    />
                                                                </Tooltip>
                                                            ) : null,
                                                    }
                                                ]}
                                            />

                                        ) : (
                                            <div style={{ marginTop: '20px' }}>
                                                <p style={{ color: 'red', fontSize: '18px' }}>
                                                    Debe seleccionar una carpeta del proyecto.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                        <Modal
                            title={
                                <span className="custom-modal-title">
                                    {`Proyecto ${tituloProyecto}`}
                                </span>
                            }
                            visible={isModalVisible}
                            onCancel={handleModalClose}
                            footer={null}
                            width="95%"
                            style={{ top: 20 }}
                        >
                            <PoaPreliminarDetalle codigo_tarea={codTarea} dataPoaPreliminar={detalleDataTree} />
                        </Modal>
                    </Layout>
                </div >
            )}
        </Layout >
    );
};

export default FileManagerPOA;
