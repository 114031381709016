import axios from 'axios';
import config from '../../../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/menuCF-details';
const API_URL = `${baseUrl}${endpoint}`;

const getMenuCF = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error al obtener el menú:', error);
    if (axios.isAxiosError(error)) {
      console.error('Detalles del error:', error.response?.data || error.message);
    }
    throw error;
  }
};

export default getMenuCF;
