import axios from 'axios';
import config from '../../../../config'; 

const baseUrl = config.baseUrl;

// Endpoints
const API_URL_CURSO = `${baseUrl}/api/cursoFortalecimientoCV`;
const API_URL_USERS = `${baseUrl}/api/usersCV`;
const API_URL_SEDES = `${baseUrl}/api/sedesCV`;
const API_URL_RECTORIAS = `${baseUrl}/api/rectoriasCV`;
const API_URL_NIVELES = `${baseUrl}/api/nivelesCV`;

export const getAllCursoFortalecimiento = async () => {
  try {
    const response = await axios.get(API_URL_CURSO);
    return response.data; 
  } catch (error) {
    console.error('Error al obtener los cursos de fortalecimiento:', error);
    throw error;
  }
};

export const getMatriculadoById = async (id) => {
  try {
    const response = await axios.get(`${API_URL_CURSO}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener el matriculado con ID: ${id}`, error);
    throw error;
  }
};

export const createMatriculado = async (matriculadoData) => {
  try {
    const response = await axios.post(API_URL_CURSO, matriculadoData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el matriculado:', error);
    throw error;
  }
};

export const updateMatriculado = async (id, matriculadoData) => {
  try {
    const response = await axios.put(`${API_URL_CURSO}/${id}`, matriculadoData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar el matriculado con ID: ${id}`, error);
    throw error;
  }
};

export const deleteMatriculado = async (id) => {
  try {
      const response = await axios.delete(`${API_URL_CURSO}/${id}`);
      return response.data;
  } catch (error) {
      console.error(`Error al eliminar el matriculado con ID: ${id}`, error);
      throw error;
  }
};

// Users
export const getAllUsers = async () => {
  try {
    const response = await axios.get(API_URL_USERS);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los usuarios:', error);
    throw error;
  }
};

export const getUserById = async (oid) => {
  try {
    const response = await axios.get(`${API_URL_USERS}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener el usuario con OID: ${oid}`, error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post(API_URL_USERS, userData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el usuario:', error);
    throw error;
  }
};

export const updateUser = async (oid, userData) => {
  try {
    const response = await axios.put(`${API_URL_USERS}/${oid}`, userData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar el usuario con OID: ${oid}`, error);
    throw error;
  }
};

export const deleteUser = async (oid) => {
  try {
    const response = await axios.delete(`${API_URL_USERS}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar el usuario con OID: ${oid}`, error);
    throw error;
  }
};

// Sedes
export const getAllSedes = async () => {
  try {
    const response = await axios.get(API_URL_SEDES);
    return response.data;
  } catch (error) {
    console.error('Error al obtener las sedes:', error);
    throw error;
  }
};

export const getSedeById = async (oid) => {
  try {
    const response = await axios.get(`${API_URL_SEDES}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener la sede con OID: ${oid}`, error);
    throw error;
  }
};

export const createSede = async (sedeData) => {
  try {
    const response = await axios.post(API_URL_SEDES, sedeData);
    return response.data;
  } catch (error) {
    console.error('Error al crear la sede:', error);
    throw error;
  }
};

export const updateSede = async (oid, sedeData) => {
  try {
    const response = await axios.put(`${API_URL_SEDES}/${oid}`, sedeData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar la sede con OID: ${oid}`, error);
    throw error;
  }
};

export const deleteSede = async (oid) => {
  try {
    const response = await axios.delete(`${API_URL_SEDES}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar la sede con OID: ${oid}`, error);
    throw error;
  }
};

// Rectorías
export const getAllRectorias = async () => {
  try {
    const response = await axios.get(API_URL_RECTORIAS);
    return response.data;
  } catch (error) {
    console.error('Error al obtener las rectorías:', error);
    throw error;
  }
};

export const getRectoriaById = async (oid) => {
  try {
    const response = await axios.get(`${API_URL_RECTORIAS}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener la rectoría con OID: ${oid}`, error);
    throw error;
  }
};

export const createRectoria = async (rectoriaData) => {
  try {
    const response = await axios.post(API_URL_RECTORIAS, rectoriaData);
    return response.data;
  } catch (error) {
    console.error('Error al crear la rectoría:', error);
    throw error;
  }
};

export const updateRectoria = async (oid, rectoriaData) => {
  try {
    const response = await axios.put(`${API_URL_RECTORIAS}/${oid}`, rectoriaData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar la rectoría con OID: ${oid}`, error);
    throw error;
  }
};

export const deleteRectoria = async (oid) => {
  try {
    const response = await axios.delete(`${API_URL_RECTORIAS}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar la rectoría con OID: ${oid}`, error);
    throw error;
  }
};

// Niveles
export const getAllNiveles = async () => {
  try {
    const response = await axios.get(API_URL_NIVELES);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los niveles:', error);
    throw error;
  }
};

export const getNivelById = async (oid) => {
  try {
    const response = await axios.get(`${API_URL_NIVELES}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener el nivel con OID: ${oid}`, error);
    throw error;
  }
};

export const createNivel = async (nivelData) => {
  try {
    const response = await axios.post(API_URL_NIVELES, nivelData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el nivel:', error);
    throw error;
  }
};

export const updateNivel = async (oid, nivelData) => {
  try {
    const response = await axios.put(`${API_URL_NIVELES}/${oid}`, nivelData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar el nivel con OID: ${oid}`, error);
    throw error;
  }
};

export const deleteNivel = async (oid) => {
  try {
    const response = await axios.delete(`${API_URL_NIVELES}/${oid}`);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar el nivel con OID: ${oid}`, error);
    throw error;
  }
};
