import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Divider, Collapse, Tooltip, Progress, message, Button, Modal, Form, Row, Col, InputNumber, Timeline, Drawer, DatePicker } from 'antd';
import { FileDoneOutlined, EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import { updateItems } from '../../services/poa/poaUpdateEjecucion';
import { insertItems } from '../../services/poa/poaInsertEjecucion';
import { selectAppPermissions } from '../../store/auth/selectors';
import FileManagerPOA from './FileManagerPOA';
import PlanPedagogicoI from './PlanPedagogicoTable';
import PoaActualizacion from './PoaActualizacion';
import PoaPreliminar from './PoaPreliminar';
import './Poa.css';
dayjs.locale('es');
dayjs.extend(relativeTime);

const PoaActividades = ({ nodo, actualizar, expandAllC }) => {
    const [, setIsModalVisible] = useState(false);
    const [isModalAvanceVisible, setIsModalAvanceVisible] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isModal1Visible, setIsModal1Visible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedRecordInicial, setSelectedRecordInicial] = useState(null);
    const [formType, setFormType] = useState('');
    const [form] = Form.useForm();
    const [formData, setFormData] = useState([]);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [planPedagogico, setPlanPedagogico] = useState(false);
    const [poaPremilinar, setPoaPremilinar] = useState(false);
    const [poaActualizacion, setPoaActualizacion] = useState(false);
    const [defaultActiveKey, setDefaultActiveKey] = useState([]);
    const appName = 'poa-actividades';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';

    const showModal1 = (record) => {
        setSelectedRecord(record);
        if (record.codActividad === '1.3' && record.key === 'indicador_17') {
            setPoaPremilinar(record);
        } else if (record.codActividad === '2.2' && record.key === 'indicador_32') {
            setPlanPedagogico(record);
        } else if (record.codActividad === '1.2' && record.key === 'indicador_10') {
            setPoaActualizacion(record);
        } else {
            setIsModal1Visible(true);
        }
    };

    const hideModal1 = () => {
        setPlanPedagogico(false);
        setPoaActualizacion(false);
        setIsModal1Visible(false);
        setSelectedRecord(null);
        setPoaPremilinar(false);
    };

    const hideModalAvance = () => {
        setIsModalAvanceVisible(false);
        setSelectedRecord(null);
        form.resetFields();
    };

    const extractData = (children) => {
        return children.flatMap(child => {
            if (child.children && child.tipo === 'Actividad') {
                const actividadData = child.children.flatMap(grandchild => {
                    if (grandchild.children && grandchild.tipo === 'Tarea') {
                        return grandchild.children.flatMap(greatGrandchild => {
                            if (greatGrandchild.tipo === 'Indicador') {
                                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                                const metaProyectada = greatGrandchild.meta_proyectada || 0;
                                const valorEjecutado = Math.floor((greatGrandchild.meta_e?.reduce((total, meta) => total + meta.valor, 0) || 0) * 100) / 100;
                                const porcentajeEjecucion = metaProyectada > 0 ? (valorEjecutado / metaProyectada) * 100 : 0;
                                const medioV = greatGrandchild.medioVerificacion || null;
                                return {
                                    key: greatGrandchild.key,
                                    codigo_tarea: grandchild.codigo_tarea,
                                    codActividad: grandchild.codActividad,
                                    nameActividad: child.actividad,
                                    tarea: grandchild.tarea,
                                    indicador: greatGrandchild.title,
                                    medioV: medioV,
                                    meta_proyectada: metaProyectada,
                                    valorEjecutado: valorEjecutado,
                                    porcentaje_ejecucion: porcentajeEjecucion % 1 === 0 ? porcentajeEjecucion.toFixed(0) : porcentajeEjecucion.toFixed(1),
                                    porcentaje_avance: greatGrandchild.meta_e?.[0]?.valor || 'N/A',
                                    fechas: fechaFormateada,
                                    responsables: 'N/A',
                                    soportes: 'N/A',
                                    datoIndicador: greatGrandchild,
                                };
                            }
                            return [];
                        });
                    }
                    return [];
                });
                const totalMetaProyectada = actividadData.reduce((total, data) => total + (data.meta_proyectada || 0), 0);
                const totalValorEjecutado = actividadData.reduce((total, data) => total + (data.meta_proyectada * (parseFloat(data.porcentaje_ejecucion) / 100) || 0), 0);
                const porcentajeEjecucionActividad = totalMetaProyectada > 0 ? (totalValorEjecutado / totalMetaProyectada) * 100 : 0;
                return [{
                    actividadTitle: child.title,
                    actividad: child.actividad,
                    porcentajeEjecucionActividad: porcentajeEjecucionActividad.toFixed(1),
                    actividadData: actividadData
                }];
            }
            return [];
        });
    };

    const [actividades, setActividades] = useState([]);

    useEffect(() => {
        const initialData = extractData(nodo.children) || [nodo];
        setActividades(initialData);
    }, [nodo.children]);

    useEffect(() => {
        setDefaultActiveKey(expandAllC ? ["panel-0", "panel-1", "panel-2", "panel-3", "panel-4", "panel-5"] : []);
    }, [expandAllC]);

    const showModalAvance = (record) => {
        setSelectedRecord(record);
        setSelectedRecordInicial({ ...record }); 
        form.setFieldsValue(record); 
        setIsModalAvanceVisible(true);
    };

    const columns = [
        {
            key: 'acciones',
            width: 40,
            align: 'center',
            render: (_, record) => (
                <Tooltip title="Medios de verificación - Cargar Soportes">
                    <Button type="primary" icon={<FileDoneOutlined />} size="middle" onClick={() => showModal1(record)}>
                    </Button>
                </Tooltip>
            ),
        },
        ...(permissionUpdate
            ? [
                {
                    dataIndex: 'fechas',
                    key: 'fechas',
                    width: 40,
                    align: 'center',
                    render: (_, record) => (
                        <Tooltip title="Editar Avance">
                            <Button type="primary" icon={<EditOutlined />} onClick={() => showModalAvance(record)} />
                        </Tooltip>
                    ),
                },
            ]
            : []
        ),
        {
            title: 'No.',
            dataIndex: 'codigo_tarea',
            key: 'codigo_tarea',
            width: 60,
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Tarea',
            dataIndex: 'tarea',
            key: 'tarea',
            width: 150,
            align: 'left',
            render: text => (
                <Tooltip title={text}>
                    <div className="truncated-text">{text.length > 25 ? `${text.slice(0, 25)}...` : text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Indicador',
            dataIndex: 'indicador',
            key: 'indicador',
            width: 150,
            align: 'left',
            render: text => (
                <Tooltip title={text}>
                    <div className="truncated-text">{text.length > 25 ? `${text.slice(0, 25)}...` : text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Meta',
            dataIndex: 'meta_proyectada',
            key: 'meta_proyectada',
            width: 60,
            align: 'center',
        },
        {
            title: 'Avance',
            dataIndex: 'valorEjecutado',
            key: 'valorEjecutado',
            width: 60,
            align: 'center',
        },
        {
            title: '% Ejecución',
            dataIndex: 'porcentaje_ejecucion',
            key: 'porcentaje_ejecucion',
            width: 130,
            align: 'center',
            render: (percent) => (
                <Progress
                    percent={parseFloat(percent) || 0}
                    strokeColor={{
                        '0%': '#042956',
                        '50%': '#338bc7',
                        '100%': '#87d068',
                    }}
                    showInfo={true}
                    format={(value) => `${percent}%`}
                    status="normal"
                />
            ),
        },

    ];

    const items = actividades.map((actividad, index) => ({
        key: `panel-${index}`,
        title: (
            <div style={{ display: 'flex', alignItems: 'center' }} className="ant-collapse-item-active">
                <span>{actividad.actividadTitle} - {actividad.actividad}</span>
                <div style={{ marginLeft: '10px', width: '250px' }}>
                    <Progress
                        percent={parseFloat(actividad.porcentajeEjecucionActividad)}
                        strokeColor={{
                            '0%': '#338bc7',
                            '100%': '#87d068',
                        }}
                        showInfo={true}
                        size="small"
                    />
                </div>
            </div>
        ),
        children: (
            <Table
                dataSource={actividad.actividadData}
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />


        ),
    }));

    const AvanceForm = ({ record }) => {
        const metaProyectadaRegistros = metaPTimeline(record.datoIndicador.meta_p);
        const metaEjecutadaRegistros = metaETimeline(record.datoIndicador.meta_e);
        useEffect(() => {
            if (record) {
                form.setFieldsValue({
                    ...record,
                    porcentaje_ejecucion_formulario: calculatePercentage(record.valorEjecutado, record.meta_proyectada),
                });
            }
        }, [record, form]);


        return (
            <div>
                <Row gutter={24} style={{ marginBottom: '5px' }}>
                    <Col span={4}>
                        <div style={{ fontWeight: 'bold' }}>Código Tarea: </div>
                    </Col>
                    <Col span={20}>
                        <div>{record.codigo_tarea}</div>
                    </Col>
                </Row>
                <Divider style={{ height: 'auto', margin: '5px 5px', backgroundColor: '#CCC' }} />
                <Row gutter={24} style={{ marginBottom: '5px' }}>
                    <Col span={4}>
                        <div style={{ fontWeight: 'bold' }}>Tarea: </div>
                    </Col>
                    <Col span={20}>
                        <div>{record.tarea}</div>
                    </Col>
                </Row>
                <Divider style={{ height: 'auto', margin: '5px 5px', backgroundColor: '#CCC' }} />
                <Row gutter={24} style={{ marginBottom: '5px' }}>
                    <Col span={4}>
                        <div style={{ fontWeight: 'bold' }}>Indicador:</div>
                    </Col>
                    <Col span={20}>
                        <div>{record.indicador}</div>
                    </Col>
                </Row>
                <Divider style={{ height: 'auto', margin: '5px 5px', backgroundColor: '#CCC' }} />
                <Row gutter={24} style={{ marginTop: '20px' }}>
                    <Col span={24}>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div style={{ flex: 1, paddingRight: '12px', borderRight: '2px solid #4caf50', margin: '0 12px' }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    Meta Proyectada: <strong>{record.meta_proyectada}</strong>
                                </div>
                                <div>{metaProyectadaRegistros}</div>
                            </div>
                            <div style={{ flex: 1, paddingLeft: '12px' }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                    Meta Ejecutada: <strong>{record.valorEjecutado}</strong>
                                </div>
                                <div>{metaEjecutadaRegistros}</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
                                    <div style={{ display: 'flex', alignItems: 'left' }}>
                                        <strong style={{ marginRight: '2px', marginTop: '14px' }}>
                                            % de Ejecución:
                                        </strong>
                                        <strong style={{ fontSize: '2rem', color: '#4CAF50' }}>
                                            {record.porcentaje_ejecucion} %
                                        </strong>
                                    </div>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        {
                                            permissionUpdate && Array.isArray(record.datoIndicador.meta_e) && record.datoIndicador.meta_e.length > 0 && (
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<EditOutlined />}
                                                    size="large"
                                                    style={{ color: '#FFF', background: '#2678cc' }}
                                                    className="custom-button-pe"
                                                    onClick={() => showDrawer('edit', record)} // Pasar el registro aquí
                                                />
                                            )
                                        }
                                        {permissionInsert && (
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<PlusOutlined />}
                                                size="large"
                                                className="custom-button-pe"
                                                style={{ color: '#FFF', background: '#2678cc' }}
                                                onClick={() => showDrawer('add')}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const calculatePercentage = (valorEjecutado, metaProyectada) => {
        if (metaProyectada === 0) return 0;
        return ((valorEjecutado / metaProyectada) * 100).toFixed(1); 
    };

    const handleValorEjecutadoChange = (value) => {
        const metaProyectada = form.getFieldValue('meta_proyectada');
        const porcentajeEjecucion = calculatePercentage(value, metaProyectada);
        form.setFieldsValue({ porcentaje_ejecucion_formulario: porcentajeEjecucion });
    };

    const metaPTimeline = (metaProyectada) => {
        const timelineItems = metaProyectada.map(item => ({
            key: item.oid,
            color: 'blue',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0px' }}>
                    <div>
                        <strong>Fecha:</strong> {dayjs(item.fecha).format('MMMM YYYY')} | <strong>Meta:</strong> {item.valor}
                    </div>
                </div>
            ),
        }));
        return (
            <Timeline mode="left" items={timelineItems} />
        );
    };

    const metaETimeline = (metaEjecutada) => {
        const timelineItems = metaEjecutada.map(item => ({
            key: item.oid,
            color: 'green',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0px' }}>
                    <div>
                        <strong>Fecha:</strong> {dayjs(item.fecha).format('MMMM YYYY')} | <strong>Meta:</strong> {item.valor}
                    </div>
                </div>
            ),
        }));
        return (
            <Timeline mode="left" items={timelineItems} style={{ margin: '0', padding: '0' }} />
        );
    };

    const showDrawer = (type, data = []) => {
        setSelectedRecordInicial(data);
        setFormType(type);
        if (type === 'edit') {
            setFormData(data.datoIndicador.meta_e);
            if (Array.isArray(data)) {
                form.setFieldsValue(
                    data.reduce((acc, item) => ({
                        ...acc,
                        [`fecha_${item.oid}`]: item.fecha,
                        [`valor_${item.oid}`]: item.valor,
                    }), {})
                );
            } else {
                form.setFieldsValue({
                    valor: data.valor || '',
                    fecha: data.fecha || '',
                });
            }
        } else if (type === 'add') {
            form.resetFields(); 
            setFormData([]); 
        }
        setIsDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        if (!isSaved && formType === 'edit') {
            form.resetFields();
            form.setFieldsValue(selectedRecordInicial);
        }
        setIsSaved(false); 
        setIsSaveButtonEnabled(false); 
        setIsDrawerVisible(false);
    };

    const formatDateForComparison = (date) => {
        return date ? dayjs(date).format('YYYY-MM-DD') : '';
    };

    const formatDateFull = (date) => {
        return date ? dayjs(date).format('YYYY-MM-DD') : '';
    };

    const compareValues = (originalValues, updatedValues) => {
        const originalMap = originalValues.reduce((map, item) => {
            map[item.oid] = {
                ...item,
                fecha: formatDateForComparison(item.fecha) 
            };
            return map;
        }, {});
        const updatedMap = updatedValues.reduce((map, item) => {
            map[item.oid] = {
                ...item,
                fecha: formatDateForComparison(item.fecha) 
            };
            return map;
        }, {});
        const changedRecords = Object.keys(updatedMap).reduce((changes, key) => {
            const updatedItem = updatedMap[key];
            const originalItem = originalMap[key];
            if (originalItem) {
                const originalValor = typeof originalItem.valor === 'string' && !isNaN(Number(originalItem.valor)) ? Number(originalItem.valor) : originalItem.valor;
                const updatedValor = typeof updatedItem.valor === 'string' && !isNaN(Number(updatedItem.valor)) ? Number(updatedItem.valor) : updatedItem.valor;
                if (updatedValor !== originalValor || updatedItem.fecha !== originalItem.fecha) {
                    changes.push(updatedItem);
                }
            } else {
                changes.push(updatedItem);
            }
            return changes;
        }, []);
        return changedRecords;
    };

    const onFinishDrawer = async (values) => {
        const entries = Object.entries(values);
        const formattedValues = entries.reduce((acc, [key, value]) => {
            const [type, oid] = key.split('_');
            if (!acc[oid]) {
                acc[oid] = { oid: parseInt(oid), valor: null, fecha: null };
            }
            if (type === 'valor') {
                acc[oid].valor = String(value);
            } else if (type === 'fecha') {
                acc[oid].fecha = formatDateFull(value); 
            }
            return acc;
        }, {});
        const formattedArray = Object.values(formattedValues);
        const changes = compareValues(selectedRecord.datoIndicador.meta_e, formattedArray);
        if (formType === 'edit') {
            // Procesar los datos para actualización
            try {
                await updateItems(changes);
                message.success('Ítems actualizados correctamente');
                const updatedMetaE = selectedRecord.datoIndicador.meta_e.map(item => {
                    const change = changes.find(c => c.oid === item.oid);
                    if (change) {
                        return {
                            ...item,
                            fecha: change.fecha || item.fecha,
                            valor: change.valor || item.valor,
                        };
                    }
                    return item;
                });
                const valorEjecutado = updatedMetaE
                    .reduce((sum, item) => sum + parseFloat(item.valor), 0)
                    .toFixed(1); 
                const porcentajeEjecucion = calculatePercentage(valorEjecutado, selectedRecord.meta_proyectada);
                const totalMetaProyectada = selectedRecord.meta_proyectada; 
                const totalValorEjecutado = updatedMetaE
                    .reduce((total, data) => total + (parseFloat(data.valor) || 0), 0)
                    .toFixed(1); 
                const porcentajeEjecucionActividad = totalMetaProyectada > 0 ?
                    ((totalValorEjecutado / totalMetaProyectada) * 100).toFixed(1) : 0;
                const updatedData = {
                    ...selectedRecord,
                    valorEjecutado,
                    porcentaje_ejecucion: porcentajeEjecucion,
                    porcentaje_ejecucion_formulario: porcentajeEjecucionActividad,
                    datoIndicador: {
                        ...selectedRecord.datoIndicador,
                        meta_e: updatedMetaE,
                    },
                };
                setSelectedRecord(updatedData);
                setIsSaved(true); 
                setIsDrawerVisible(false);
                actualizar();
                setIsModalVisible(false);
            } catch (error) {
                message.error('Error al actualizar los ítems ');
            }
        } else if (formType === 'add') {
            const oidIndicador = selectedRecord?.datoIndicador.oid || null; 
            const { fecha, ejecucion } = values;
            const payload = {
                oidIndicador,
                fecha: formatDateFull(fecha),
                valor: String(ejecucion),
            };
            try {
                const updatedMetaE = [...selectedRecord.datoIndicador.meta_e, payload];
                await insertItems(payload);
                message.success('Ítem insertado correctamente');
                const valorEjecutado = updatedMetaE
                    .reduce((sum, item) => sum + parseFloat(item.valor || 0), 0)
                    .toFixed(2);
                const porcentajeEjecucion = calculatePercentage(valorEjecutado, selectedRecord.meta_proyectada);
                const totalMetaProyectada = selectedRecord.meta_proyectada; 
                const totalValorEjecutado = updatedMetaE
                    .reduce((total, data) => total + (parseFloat(data.valor) || 0), 0)
                    .toFixed(2);  
                const porcentajeEjecucionActividad = totalMetaProyectada > 0 ?
                    ((totalValorEjecutado / totalMetaProyectada) * 100).toFixed(2) : 0;
                const updatedData = {
                    ...selectedRecord,
                    valorEjecutado,
                    porcentaje_ejecucion: porcentajeEjecucion,
                    porcentaje_ejecucion_formulario: porcentajeEjecucionActividad,
                    datoIndicador: {
                        ...selectedRecord.datoIndicador,
                        meta_e: updatedMetaE,
                    },
                };
                setSelectedRecord(updatedData);
                setIsSaved(true);
                setIsDrawerVisible(false);
                actualizar();
            } catch (error) {
                message.error('Error al insertar el ítem');
            }
        }
    };

    const collapseItems = items.map(item => ({
        key: item.key,
        label: item.title,
        children: item.children,
    }));

    useEffect(() => {
        if (isDrawerVisible && selectedRecord) {
            form.resetFields();
            form.setFieldsValue({
                ...selectedRecord,
                datoIndicador: {
                    meta_e: selectedRecord.datoIndicador.meta_e.map(item => ({
                        [`valor_${item.oid}`]: item.valor,
                        [`fecha_${item.oid}`]: item.fecha,
                    })),
                },
            });
        }
    }, [isDrawerVisible, selectedRecord]);

    return (
        <div className="container-fluid resultadosActividades" style={{ marginLeft: '0px !important'}}>
            <Collapse key={expandAllC ? 'expanded' : 'collapsed'} defaultActiveKey={defaultActiveKey} items={collapseItems} />
            <Modal
                title={<span className="custom-modal-title">Ejecución de la Meta</span>}
                open={isModalAvanceVisible}
                onCancel={hideModalAvance}
                footer={null}
                width="60%"
                style={{ top: 20 }}
            >
                <AvanceForm record={selectedRecord} />
            </Modal>
            <Drawer
                title={formType === 'edit' ? "Editar avance de Ejecución" : "Registrar avance de Ejecución"}
                placement="right"
                onClose={handleDrawerClose}
                open={isDrawerVisible}
                header={{ background: '#042956', padding: '16px' }} // Estilo para el encabezado
                closeIcon={<CloseOutlined style={{ color: '#1890ff', fontSize: '16px' }} />} // Estilo para el botón de cerrar
            >
                <Form form={form} layout="vertical" onFinish={onFinishDrawer} onValuesChange={(changedValues) => {
                    setIsSaveButtonEnabled(Object.keys(changedValues).length > 0);
                }}
                >
                    {formType === 'edit' ? (
                        formData.map(item => (
                            <Row key={item.oid} gutter={24} style={{ marginBottom: '0px' }}>
                                <Col span={12}>
                                    <Form.Item
                                        label={`Avance: ID ${item.oid}`}
                                        name={`valor_${item.oid}`}
                                        initialValue={item.valor}
                                        rules={[{ required: true, message: 'Por favor ingrese el valor' }]}
                                        style={{ marginBottom: '15px' }}
                                    >
                                        <InputNumber
                                            min={0}
                                            step={0.01}
                                            style={{ width: '100%' }}
                                            placeholder="Ingrese el valor"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={`Fecha: OID ${item.oid}`}
                                        name={`fecha_${item.oid}`}
                                        initialValue={item.fecha ? dayjs(item.fecha) : null}
                                        rules={[{ required: true, message: 'Por favor seleccione la fecha' }]}
                                        style={{ marginBottom: '15px' }}
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="Selecciona una fecha" />
                                    </Form.Item>
                                </Col>
                                <Divider style={{ margin: '4px 0', background: '#3F51B5' }} />
                            </Row>
                        ))
                    ) : (
                        <React.Fragment>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{ required: true, message: 'Por favor seleccione la fecha' }]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Selecciona una fecha" />
                            </Form.Item>

                            <Form.Item
                                label="Ejecución"
                                name="ejecucion"
                                rules={[{ required: true, message: 'Por favor ingrese el valor de ejecución' }]}
                            >
                                <InputNumber
                                    min={0}
                                    step={0.01}
                                    style={{ width: '100%' }}
                                    placeholder="Ingrese el valor de ejecución"
                                    onChange={handleValorEjecutadoChange}
                                />
                            </Form.Item>
                        </React.Fragment>
                    )}
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Tooltip title="Actualizar los valores de la meta ejecutada">
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ fontSize: '16px', padding: '10px 20px', marginTop: '10px' }}
                                disabled={!isSaveButtonEnabled}
                            >
                                Guardar
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Drawer>
            <div className="container-fluid py-3">
                {isModal1Visible && selectedRecord && (
                    <Modal
                        title={<span className="custom-modal-title">Medios de verificación</span>}
                        open={isModal1Visible}
                        onCancel={hideModal1}
                        footer={null}
                        width="95%" 
                        style={{ top: 20 }} 
                    >
                        <FileManagerPOA codigo_tarea={selectedRecord} />
                    </Modal>
                )}
            </div>
            <div className="container-fluid py-3">
                {planPedagogico && selectedRecord && (
                    <Modal
                        title={<span className="custom-modal-title">Medios de verificación</span>}
                        open={planPedagogico}
                        onCancel={hideModal1}
                        footer={null}
                        height="95%"
                        width="95%"
                        style={{ top: 20 }}
                    >
                        <PlanPedagogicoI codigo_tarea={selectedRecord} />
                    </Modal>
                )}
                {poaActualizacion && selectedRecord && (
                    <Modal
                        title={<span className="custom-modal-title">POA Actualización</span>}
                        visible={poaActualizacion}
                        onCancel={hideModal1}
                        footer={null}
                        width="95%"
                        style={{ top: 20 }}
                    >
                        <PoaActualizacion codigo_tarea={selectedRecord} />
                    </Modal>
                )}
                 {poaPremilinar && selectedRecord && (
                    <Modal
                        title={<span className="custom-modal-title">Proyectos Estructurados</span>}
                        visible={poaPremilinar}
                        onCancel={hideModal1}
                        footer={null}
                        width="95%"
                        style={{ top: 20 }}
                    >
                        <PoaPreliminar codigo_tarea={selectedRecord} />
                    </Modal>
                )}
            </div>
        </div >
    );
};

export default PoaActividades;
