import axios from "axios";
import config from "../../../../config"; // Configuración de la URL base

// Construcción de la URL base
const baseUrl = config.baseUrl;
const endpoint1 = "/api/asistencia_dhaa";
const endpoint2 = "/api/tipo_asistencia_dhaa";
const API_URL_1 = `${baseUrl}${endpoint1}`;
const API_URL_2 = `${baseUrl}${endpoint2}`; 

const AsistenciaServicio = {
  // Obtener todos los registros
  getAllAsistencia: async () => {
    try {
      const response = await axios.get(API_URL_1);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los datos generales", error);
      throw error;
    }
  },

  // Obtener un registro por OID
  getAsistenciaById: async (oid) => {
    try {
      const response = await axios.get(`${API_URL_1}/${oid}`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener el registro con OID ${oid}`, error);
      throw error;
    }
  },

  // Crear un nuevo registro
  createAsistencia: async (data) => {
    try {
      const response = await axios.post(API_URL_1, data);
      return response.data;
    } catch (error) {
      console.error("Error al crear el registro", error);
      throw error;
    }
  },

  // Actualizar un registro por OID
  updateAsistencia: async (oid, data) => {
    try {
      const response = await axios.put(`${API_URL_1}/${oid}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error al actualizar el registro con OID ${oid}`, error);
      throw error;
    }
  },

  // Eliminar un registro por OID
  deleteAsistencia: async (oid) => {
    try {
      const response = await axios.delete(`${API_URL_1}/${oid}`);
      return response.data;
    } catch (error) {
      console.error(`Error al eliminar el registro con OID ${oid}`, error);
      throw error;
    }
  },

  getTiposAsistencia: async () => {
    const response = await axios.get(`${API_URL_2}`);
    return response.data;
  },
  
  getTipoAsistencia: async (id) => {
    const response = await axios.get(`${API_URL_2}/${id}`);
    return response.data;
  },
  
  addTipoAsistencia: async (nombre) => {
    const response = await axios.post(`${API_URL_2}`, { nombre });
    return response.data;
  },
  
  modifyTipoAsistencia: async (id, nombre) => {
    const response = await axios.put(`${API_URL_2}/${id}`, { nombre });
    return response.data;
  },
  
  removeTipoAsistencia: async (id) => {
    const response = await axios.delete(`${API_URL_2}/${id}`);
    return response.data;
  },
}


export default AsistenciaServicio;