import axios from 'axios';
import config from '../../../../config'; // Configuración para obtener la URL base

const baseUrl = config.baseUrl;
const endpoint = '/api'; // Endpoint base
const API_URL = `${baseUrl}${endpoint}`;

const GeneralService = {
  // Obtener subregiones PDET
  getSubregionesPDET: async () => {
    const response = await axios.get(`${API_URL}/subregiones_dhaa`);
    return response.data;
  },

  // Obtener municipios
  getMunicipios: async () => {
    const response = await axios.get(`${API_URL}/municipios_dhaa`);
    return response.data;
  },

  // Obtener departamentos
  getDepartamentos: async () => {
    const response = await axios.get(`${API_URL}/departamentos_dhaa`);
    return response.data;
  }
};

export default GeneralService;
