import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;

// Servicio para obtener los registros de `ippta_plan_accion` con un parámetro
export const getPlanAccion = async (oidIppta_general) => {
  try {
    console.log(`${baseUrl}/api/plan_accion?oidIppta_general=${oidIppta_general}`);
    const response = await axios.get(`${baseUrl}/api/plan_accion?oidIppta_general=${oidIppta_general}`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener plan de acción:', error);
    throw error;
  }
};

// Función para listar los registros
export const getAllPlanAccion = async (oidIpptaGeneral, oidDimension) => {
  try {
    const response = await axios.get(`${baseUrl}/api/plan-accion-filt`, {
      params: { oidIppta_general: oidIpptaGeneral, oid_dimension: oidDimension },
    });
    return response.data; // Devuelve los datos obtenidos
  } catch (error) {
    console.error('Error al obtener los registros:', error);
    throw error; // Propaga el error para manejarlo en el componente que lo llame
  }
};

// Función para crear un nuevo registro
export const createPlanAccion = async (data) => {
  try {
      const response = await axios.post(`${baseUrl}/api/plan-accion`, data);
      return response.data;  // Devuelve el ID del nuevo registro creado
  } catch (error) {
      console.error('Error al crear el registro:', error);
      throw error;
  }
};


// Función para actualizar un registro
export const updatePlanAccion = async (data) => {
  try {
    const response = await axios.put(`${baseUrl}/api/plan-accion/`, data);
    return response.data; // Devuelve las filas actualizadas
  } catch (error) {
    console.error('Error al actualizar el registro:', error);
    throw error;
  }
};

// Función para eliminar un registro
export const removePlanAccion = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/plan-accion/${id}`);
    return response.data; // Devuelve las filas eliminadas
  } catch (error) {
    console.error('Error al eliminar el registro:', error);
    throw error;
  }
};


// Servicio para obtener todos los indicadores de `ippta_indicador`
export const getIndicadores = async () => {
  try {
    const response = await axios.get(`${baseUrl}/indicadorPA`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener indicadores:', error);
    throw error;
  }
};

// Servicio para obtener todas las dimensiones de `ippta_dimension`
export const getDimensiones = async () => {
  try {
    const response = await axios.get(`${baseUrl}/dimensionPA`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener dimensiones:', error);
    throw error;
  }
};
