import React, { useEffect, useState, useRef } from "react";
import { Table, Row, Col, Select, Input, Tooltip, Button, Form, Space, Popconfirm, message, Modal } from "antd";
import GeneralService from "../../../../services/Producto_3/Fort_Capacidades/DHAA/cursoFortalecimientoDHAA";
import FiltroService from "../../../../services/Producto_3/Fort_Capacidades/Filtro/filtroService";
import { EditOutlined, DeleteOutlined, PlusOutlined, FileDoneOutlined, SearchOutlined} from '@ant-design/icons';
import { selectAppPermissions } from '../../../../store/auth/selectors';
import { useSelector } from 'react-redux';
import EscuelaDHAAForm from "./EscuelaDHAAForm";
import EscuestaAsistencia from "./EscuelaDHHAAsistencia"

const { Option } = Select;

const EscuelaDHAATable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filterDepartamento, setFilterDepartamento] = useState(null);
  const [filterMunicipio, setFilterMunicipio] = useState(null);
  const [filterSubSesion, setFilterSubSesion] = useState(null);
  const [subregion, setSubregion] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [filterCedula, setFilterCedula] = useState("");
  const [activeTab, setActiveTab] = useState('1');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const generalFormRef = useRef(null);
  const preguntasFormRef = useRef(null);
  const incidenciasFormRef = useRef(null);
  const [isModalVisibleAsis, setIsModalVisibleAsis] = useState(false);

  const appName = 'escuela_dhaa_table';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionInsert = permissions.priv_insert === 'Y';
  //const permissionExport = permissions.priv_export === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';
  const permissionDelete = permissions.priv_delete === 'Y';


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          departamentosData,
          municipiosData,
          subregionesData
        ] = await Promise.all([
          FiltroService.getDepartamentos(),
          FiltroService.getMunicipios(),
          FiltroService.getSubregionesPDET()
        ]);
        // Asignar los datos a los estados correspondientes
        setDepartamentos(departamentosData);
        setMunicipios(municipiosData);
        setSubregion(subregionesData);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    GeneralService.getAllGeneral()
      .then((response) => {
        setData(response);
       
        
      })
      .catch((error) => console.error("Error al obtener los datos:", error))
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = async (data) => {
    try {
      await GeneralService.deleteGeneral(data.codGeneral);
      message.success('Participante eliminado correctamente');
      setData((prevData) => prevData.filter((user) => user.codGeneral !== data.codGeneral));
    } catch (error) {
      message.error('Error al eliminar el participante, tiene sesiones asociadas o ocurrió otro error');
    }
  };

  const showModalSesion = (user) => {
    setEditingUser(user.codGeneral);   
    setIsModalVisibleAsis(true);       
  };
  
  // Función para cerrar el modal
  const handleCancelAsis = () => {
    setIsModalVisibleAsis(false);
    setEditingUser(null); // Opcional, para limpiar el estado al cerrar
  };

  const handleEdit = (user) => {
    setEditingUser(user.codGeneral);
    setIsModalVisible(true);
  };


  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre_apellido",
      key: "nombre_apellido",
    },
    {
      title: "Tipo Documento",
      dataIndex: "nombre_tipo_documento",
      key: "nombre_tipo_documento",
    },
    {
      title: "Número Documento",
      dataIndex: "numero_documento",
      key: "numero_documento",
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
    },
    {
      title: "Sexo",
      dataIndex: "nombre_sexo",
      key: "nombre_sexo",
    },
    {
      title: "Edad",
      dataIndex: "edad",
      key: "edad",
    },
    {
      title: "Grupo etáreo",
      dataIndex: "etareo",
      key: "etareo",
    },
    {
      title: "Municipio",
      dataIndex: "municipio",
      key: "municipio",
    },
    {
      title: "Vereda",
      dataIndex: "vereda",
      key: "vereda",
    },
    {
      title: "Etnia",
      dataIndex: "etnico",
      key: "etnico",
    },
    {
      title: "Organización",
      dataIndex: "organizacion",
      key: "organizacion",
    },
    {
      title: "Problemática priorizada",
      dataIndex: "accion_problematica",
      key: "accion_problematica",
      render: (text) => text ?? "Sin asignar",
    },
    {
      title: "Incidencia",
      dataIndex: "incidencia",
      key: "incidencia",
      render: (text) => text ?? "Sin asignar",
    },{
      title: "Accion Social",
      dataIndex: "accion_social",
      key: "accion_social",
      render: (text) => text ?? "Sin asignar",
    },
    {
      title: "Asistencias",
      dataIndex: "Asistencias",
      align: "center",
      key: "Asistencias",
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      render: (_, record) => (
        <Space size="small">
          {permissionUpdate && (
            <Tooltip title="Editar participante">
              <Button type="primary" icon={<EditOutlined />} style={{ backgroundColor: '#f0ab18', color: '#ffffff' }} onClick={() => handleEdit(record)} />
            </Tooltip>
          )}{permissionInsert && (
            <Tooltip title="Agregar Sesiones">
              <Button type="primary" icon={<FileDoneOutlined />} onClick={() => showModalSesion(record)} />
            </Tooltip>
          )} {permissionDelete && (
            <Tooltip title="Eliminar participante">
              <Popconfirm
                title="¿Estás seguro de eliminar este usuario?"
                onConfirm={() => handleDelete(record)}
                okText="Sí"
                cancelText="No"
              >
                <Button type="primary" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    }
  ];

  const handleDepartamentoChange = (value) => {
    setFilterDepartamento(value);
    const filtered = municipios.filter(municipio => municipio.oid_depto === value);
    setFilteredMunicipios(filtered);
    setFilterMunicipio(null);
  };

  const handleClearFilters = () => {
    setActiveTab('1');
    setFilterSubSesion(null);
    setFilterDepartamento(null);
    setFilterMunicipio(null);
    setFilterCedula("");
    //form.resetFields();
    //fetchUsuarios();
  };

  const filteredData = data.filter(user => {
    return (
      (!filterDepartamento || user.id_departamento === filterDepartamento) &&
      (!filterMunicipio || user.id_municipio === filterMunicipio) &&
      (!filterSubSesion || user.regional_id === filterSubSesion) &&
      (!filterCedula || (user.numero_documento && user.numero_documento.toString().includes(filterCedula.toString())))
    );
  });

  const showModal = () => {
    resetForms(); 
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    if (editingUser === null) {
      hideModal();
      resetForms();
      setEditingUser(null);
    } else {
      hideModal();
    }
  };
  

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const resetForms = () => {
    generalFormRef.current?.resetFields();
    preguntasFormRef.current?.resetFields();
    incidenciasFormRef.current?.resetFields();
  };

  return (
    <div className="container">
      <div>
        <Row gutter={[16, 16]} style={{ width: '100%' }}>
          <Col span={4}>
            <Select
              showSearch
              placeholder="Filtrar por Sub Región"
              value={filterSubSesion || undefined}
              onChange={(value) => setFilterSubSesion(value)}
              className="filtro-item"
              style={{ width: '100%' }}
            >
              {subregion.map(subRegion => (
                <Option key={subRegion.oid} value={subRegion.oid}>{subRegion.nombre_subregion_pdet}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              showSearch
              placeholder="Filtrar por Departamento"
              value={filterDepartamento || undefined}
              onChange={handleDepartamentoChange}
              onSearch={(value) => setFilterDepartamento(value)}
              className="filtro-item"
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {departamentos.map(depto => (
                <Option key={depto.oid} value={depto.oid}>{depto.departamento}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              showSearch
              placeholder="Filtrar por Municipio"
              value={filterMunicipio || undefined}
              onChange={(value) => setFilterMunicipio(value)}
              className="filtro-item"
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {filteredMunicipios.map(mun => (
                <Option key={mun.oid} value={mun.oid}>{mun.municipio}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Input
              placeholder="Filtrar por Cédula"
              value={filterCedula}
              style={{ width: '100%' }}
              onChange={(e) => setFilterCedula(e.target.value)}
              className="filtro-item"
            />
          </Col>
          <Col span={4} >
            <Tooltip title="Limpiar filtros">
              <Button
                icon={<SearchOutlined style={{ color: '#000000 !important' }} />}
                onClick={handleClearFilters}
                style={{
                  backgroundColor: '#1677FF',
                  color: '#ffffff',
                  width: '100%'
                }}
              >
                Limpiar Filtros
              </Button>
            </Tooltip>
          </Col>
          {permissionInsert && (
            <Col span={4}>
              <Tooltip title="Crear Participantes">
                <Button
                  icon={<PlusOutlined />}
                  onClick={showModal}
                  style={{
                    backgroundColor: '#3BB44A',
                    color: '#ffffff',
                    width: '100%'
                  }}
                >
                  Crear Participante
                </Button>
              </Tooltip>
            </Col>
          )}
        </Row>
      </div>
      <div className="participant-count-container">
        <h1 className="participant-count-title">
          Número de Participantes: <span className="participant-count-number">{filteredData.length}</span>
        </h1>
      </div>
      <div className="tabla-container">
        <Table
          className="table-curso-fortalecimiento"
          columns={columns}
          dataSource={filteredData}
          rowKey="codGeneral"
          loading={loading}
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: filteredData.length === 0 ? 'No se han registrado usuarios aún' : 'No hay usuarios disponibles en este momento',
          }} 
        />
      </div>
      <div>
        <Modal
          title="Registrar Escuela de DHAA"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={1200}
        > 
           <EscuelaDHAAForm 
            generalFormRef={generalFormRef}
            preguntasFormRef={preguntasFormRef}
            incidenciasFormRef={incidenciasFormRef}
            onFinish={handleCancel} 
            user={editingUser}
            />
        </Modal>
        <Modal
        title="Registro de asistencia"
        open={isModalVisibleAsis} // Cambiado de visible a open en Ant Design 5
        onCancel={handleCancelAsis}
        footer={null}
        width={1000}
      >
        <EscuestaAsistencia oidData={editingUser} />
      </Modal>
      </div>
    </div>
  );
};

export default EscuelaDHAATable;
