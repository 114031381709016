// src/services/cursoFortalecimientoUserService.js
import axios from 'axios';
import config from '../../../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/cursoFortalecimientousuarios';
const API_URL = `${baseUrl}${endpoint}`;

// Obtener todos los registros
export const getUsuarios = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// Obtener un registro por ID
export const getUsuarioById = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};

// Crear un nuevo registro
export const createUsuario = async (usuarioData) => {
  const response = await axios.post(API_URL, usuarioData);
  return response.data;
};

// Actualizar un registro por ID
export const updateUsuario = async (id, usuarioData) => {
  const response = await axios.put(`${API_URL}/${id}`, usuarioData);
  return response.data;
};

// Eliminar un registro por ID
export const deleteUsuario = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};
